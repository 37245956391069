<template>
  <el-dialog
    v-model="DLGVisible"
    :before-close="closeDLG"
    width="40%"
    destroy-on-close
    class="base-dialog"
  >
    <template #header>
      <BaseDialogTitle title="一键分析" :item-name="textLibraryName" />
    </template>
    <DataRecordDialogTableAnalysisSelector
      ref="dataRecordDialogTableAnalysisSelectorRef"
    />
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="closeDLG">取消</el-button>
        <el-button
          type="primary"
          @click="handleClickConfirm"
          :disabled="saveButtonDisable"
        >
          一键分析
        </el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script setup>
import { reactive, ref, defineProps, defineEmits, defineExpose } from "vue";
import { httpPost } from "@/api/httpService.js";
import BaseDialogTitle from "@/components/base/BaseDialogTitle.vue";
import DataRecordDialogTableAnalysisSelector from "./DataRecordDialogTableAnalysisSelector.vue";
import { getFolderLevelListByTextLibraryId } from "../api/getFolderLevelList";
import { useRoute, useRouter } from "vue-router";
// import router from "../../../router";

const route = useRoute();
const router = useRouter();
const libraryId = route.params.textLibraryId;
// 获取文本库名称
const textLibraryName = ref("");
const folderLevelList = ref([]);
const getFolderLevelList = async () => {
  folderLevelList.value = await getFolderLevelListByTextLibraryId(libraryId);
  textLibraryName.value =
    folderLevelList.value[folderLevelList.value.length - 1].name;
};

const saveButtonDisable = ref(false);
const showSaveButtonDisable = () => {
  saveButtonDisable.value = true;
};
const hideSaveButtonDisable = () => {
  saveButtonDisable.value = false;
};
const emits = defineEmits([]);
// 弹窗相关
const DLGVisible = ref(false);
const openDLG = (params) => {
  DLGVisible.value = true;
  getFolderLevelList();
};
const closeDLG = () => {
  DLGVisible.value = false;
};
// 点击确认
const handleClickConfirm = () => {
  showSaveButtonDisable();
  createAnalysis();

  // DLGVisible.value = false;
  // hideSaveButtonDisable();
};

const dataRecordDialogTableAnalysisSelectorRef = ref(null);
const createAnalysis = () => {
  let url = "/lib/record/oneKeyAnalysis/start";
  let oneKeyAnalysisList = [];
  dataRecordDialogTableAnalysisSelectorRef.value.variablesModelMapList.forEach(
    (item) => {
      item.oneKeyAnalysisList.forEach((templateType) => {
        oneKeyAnalysisList.push({
          fieldName: item.fieldName,
          templateType: templateType
        });
      });
    }
  );
  let params = {
    libraryId: libraryId,

    oneKeyAnalysisList
  };
  httpPost(url, params).then((res) => {
    if (res.code == 0) {
      router.push({
        name: "AnalysisModel",
        query: {
          analysisId: res.data
          // type: 1
        }
      });
    }
  });
};
defineExpose({ openDLG, closeDLG });
</script>
<style lang="scss" scoped></style>
