<template>
  <div class="table-wrapper list-table">
    <div class="option-bar">
      <div class="search-box">
        <el-input
          v-model="keywords"
          placeholder="输入文本库名称进行检索"
          style="width: 424px"
        ></el-input>
        <el-button type="primary" @click="getTextLibraryList">搜索</el-button>
      </div>

      <div class="button-group">
        <span>
          <el-dropdown @command="handleBatchCommand">
            <el-button>
              批量操作
              <el-icon class="el-icon--right"><arrow-down /></el-icon>
            </el-button>
            <template #dropdown>
              <el-dropdown-menu>
                <el-dropdown-item command="batchDelete">
                  批量删除
                </el-dropdown-item>
                <el-dropdown-item command="batchMove">
                  批量移动
                </el-dropdown-item>
              </el-dropdown-menu>
            </template>
          </el-dropdown>
        </span>

        <el-button @click="handleClickCreateFolder">新建文件夹</el-button>
        <el-button type="primary" @click="handleClickCreateTextLibrary">
          新建文本库
        </el-button>
      </div>
    </div>
    <div class="title-bar">
      <div class="title"><span>文本库列表</span></div>
    </div>
    <el-table
      :data="textLibraryList"
      v-loading="tableLoading"
      header-cell-class-name="list-table-header"
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" width="45px" />
      <el-table-column prop="name" label="名称" min-width="200">
        <template #default="scope">
          <div v-if="!scope.row.ifEdit" class="library-name list-item">
            <div
              v-if="scope.row.contentType == CONTENT_TYPE.FOLDER"
              @click="goToFolderPage(scope.row)"
            >
              <i class="iconfont icon-fill-folder list-item-icon"></i>
              <span>{{ scope.row.name }}</span>
              <i
                v-if="!ifPresentationFolder(scope.row)"
                class="iconfont icon-bianji edit-icon"
                @click.stop="showEditor(scope.row)"
              ></i>
            </div>
            <div
              v-else-if="scope.row.contentType == CONTENT_TYPE.LIBRARY"
              @click="goToLiraryPage(scope.row)"
            >
              <i class="iconfont icon-textLibrary list-item-icon"></i>
              <span>{{ scope.row.name }}</span>
              <i
                class="iconfont icon-bianji edit-icon"
                @click.stop="showEditor(scope.row)"
              ></i>
            </div>
            <div
              v-else-if="scope.row.contentType == CONTENT_TYPE.SHARED_LIBRARY"
              @click="goToLiraryPage(scope.row)"
            >
              <i class="iconfont icon-textLibrary list-item-icon"></i>
              <span>{{ scope.row.name }}</span>
            </div>
          </div>
          <div v-else class="rename-box">
            <TextEditor
              :text="scope.row.name"
              :editable="scope.row.userId != SYSTEM_USER_ID"
              v-model:edit="scope.row.ifEdit"
              v-model:loading="scope.row.ifShowLoading"
              @save="(text) => handleClickSaveListItemName(text, scope.row)"
              @cancel="handleClickCancelRename(scope)"
            ></TextEditor>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="recordNum" label="记录数" min-width="100">
        <template #default="scope">
          <span>
            {{ scope.row.recordNum != null ? scope.row.recordNum : "--" }}
          </span>
        </template>
      </el-table-column>
      <el-table-column prop="fieldNum" label="变量数" min-width="100">
        <template #default="scope">
          <span>
            {{ scope.row.fieldNum != null ? scope.row.fieldNum : "--" }}
          </span>
        </template>
      </el-table-column>
      <el-table-column
        prop="username"
        label="创建人"
        show-overflow-tooltip
        width="150"
      />
      <el-table-column prop="updateTime" label="更新时间" min-width="100">
        <template #default="scope">
          {{ timeFormatter(scope.row.updateTime) }}
        </template>
      </el-table-column>
      <el-table-column label="操作" width="200">
        <template #default="scope">
          <div class="list-item-btn" v-if="!scope.row.ifEdit">
            <el-button
              link
              @click="goToDataRecord(scope.row)"
              v-if="
                scope.row.contentType == CONTENT_TYPE.LIBRARY ||
                scope.row.contentType == CONTENT_TYPE.SHARED_LIBRARY
              "
            >
              数据
            </el-button>
            <el-button
              v-if="
                scope.row.contentType == CONTENT_TYPE.FOLDER &&
                !ifPresentationFolder(scope.row)
              "
              link
              @click="handleClickDeleteFolder(scope.row)"
            >
              删除
            </el-button>
            <el-button
              v-if="
                scope.row.contentType != CONTENT_TYPE.FOLDER &&
                scope.row.userId != SYSTEM_USER_ID
              "
              link
              @click="handleClickAnalysis(scope.row)"
            >
              分析
            </el-button>
            <el-button
              link
              @click="showEditor(scope.row)"
              v-if="
                scope.row.contentType == CONTENT_TYPE.FOLDER &&
                !ifPresentationFolder(scope.row)
              "
            >
              重命名
            </el-button>

            <span
              class="list-item-dropdown"
              v-if="
                scope.row.contentType == CONTENT_TYPE.LIBRARY ||
                scope.row.contentType == CONTENT_TYPE.SHARED_LIBRARY
              "
            >
              <el-dropdown
                @command="(command) => handleCommand(scope.row, command)"
              >
                <el-button link>
                  更多
                  <el-icon class="el-icon--right"><arrow-down /></el-icon>
                </el-button>
                <template #dropdown>
                  <el-dropdown-menu>
                    <el-dropdown-item command="copy">复制</el-dropdown-item>
                    <el-dropdown-item
                      v-if="scope.row.userId != SYSTEM_USER_ID"
                      command="move"
                    >
                      移动
                    </el-dropdown-item>
                    <el-dropdown-item
                      v-if="scope.row.userId != SYSTEM_USER_ID"
                      command="delete"
                    >
                      删除
                    </el-dropdown-item>
                    <el-dropdown-item
                      v-if="scope.row.userId != SYSTEM_USER_ID"
                      command="rename"
                    >
                      重命名
                    </el-dropdown-item>
                  </el-dropdown-menu>
                </template>
              </el-dropdown>
            </span>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <div class="pagination-box">
      <el-pagination
        v-model:current-page="paginationState.pageNum"
        v-model:page-size="paginationState.pageSize"
        small
        background
        layout="total, prev, pager, next, sizes"
        :total="paginationState.total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :page-sizes="paginationState.pageSizeList"
      />
    </div>
  </div>
  <TextLibraryDialogCreate
    ref="textLibraryDLGCreateRef"
    @submit="getTextLibraryList"
  />
  <TextLibraryDialogComfirmDelete
    ref="textLibraryDLGConfirmDeleteRef"
    @confirm="getTextLibraryList"
  />
  <TextLibraryDialogTreeSelect
    ref="textLibraryDLGTreeSelectRef"
    @refresh="getTextLibraryList"
  />
</template>

<script setup>
import {
  reactive,
  ref,
  defineProps,
  defineEmits,
  watch,
  onMounted,
  computed
} from "vue";
import { httpPost } from "@/api/httpService.js";

import TextLibraryDialogCreate from "./TextLibraryDialogCreate.vue";
import TextLibraryDialogComfirmDelete from "./TextLibraryDialogComfirmDelete.vue";
import TextLibraryDialogTreeSelect from "./TextLibraryDialogTreeSelect.vue";
import TextEditor from "@/components/base/TextEditor.vue";

import { CONTENT_TYPE } from "@/constant/text_library";
import { useRouter, useRoute } from "vue-router";
import { timeFormatter } from "@/utils/time.js";
import { ElMessage, ElMessageBox } from "element-plus";
import { Debounce } from "@/utils/utils";
import { confirmDeleteBox } from "@/utils/messageBox";
import Folder from "@/class/Folder.js";
import {
  DELETE_LIBRARY_CHILD_ERROR,
  DELETE_LIBRARY_ANALYSIS_ERROR,
  DELETE_LIBRARY_CHILD_AND_ANALYSIS_ERROR
} from "@/constant/error_code.js";
import { SYSTEM_USER_ID } from "@/constant/system.js";
import {
  ERROR_CODE_NAME_DUPLICATE,
  PARAM_VALID_ERROR
} from "@/constant/error_code.js";
const router = useRouter();
const route = useRoute();
const props = defineProps({
  folderLevelList: {
    type: Array
  }
});

// 文本库列表数据
const textLibraryList = ref([]); // 列表数据
const keywords = ref("");

//获取当前页面信息（列表数据、文件夹路径）
const getTextLibraryList = async () => {
  showTableLoading();
  let url = "/view/get";
  let params = {
    parentFolderId: currentFolderId.value,
    keywords: keywords.value,
    pageNum: paginationState.pageNum,
    pageSize: paginationState.pageSize
  };
  let res = await httpPost(url, params);
  hideTableLoading();
  if (res.code == 0) {
    textLibraryList.value = res.data.pageList.data;
    // folderLevelList.value = res.data.folderLevelList;
    paginationState.pageNum = res.data.pageList.pageNum;
    paginationState.total = res.data.pageList.total;
    paginationState.pageSize = res.data.pageList.pageSize;
  }
};
//文本库加载
const tableLoading = ref(false);
const showTableLoading = () => {
  tableLoading.value = true;
};
const hideTableLoading = () => {
  tableLoading.value = false;
};
// 文本库列表操作
const ifPresentationFolder = computed(() => {
  return (row) => {
    return currentFolderId.value == ROOT_FOLDER.id && row.name == "演示文件夹";
  };
});
// 显示重命名输入框
const showEditor = (row) => {
  row.newName = row.name;
  row.ifEdit = true;
};
const hideEditor = (row) => {
  row.newName = "";
  row.ifEdit = false;
};
// 保存重命名
const handleClickSaveListItemName = Debounce(async (text, row) => {
  if (!text) {
    ElMessage.warning("名称不能为空");
    row.ifShowLoading = false;
    return;
  }
  let params = {};
  if (row.contentType == CONTENT_TYPE.FOLDER) {
    params = {
      ...row,
      name: text,
      ifChildFolder: isFolder(row.contentType)
    };
  } else {
    params = {
      id: row.id,
      name: text,
      parentFolderId: row.parentFolderId,
      ifChildFolder: isFolder(row.contentType)
    };
  }

  let res = await saveTextLibraryOrFolder(params);
  if (res.code == 0) {
    //保存成功,更新单行，并重新获取最新列表数据
    ElMessage.success(res.message);
    row.name = text;
    row.ifEdit = false;
    getTextLibraryList();
  } else if (
    res.code == ERROR_CODE_NAME_DUPLICATE ||
    res.code == PARAM_VALID_ERROR
  ) {
    //系统已知错误,用户重新编辑
    ElMessage.warning(res.message);
    row.ifShowLoading = false;
  } else {
    //系统未知错误,由外部捕获错误，然后重新获取列表数据
    getTextLibraryList();
  }
});
// 根据contentType判断是否吃文件夹
const isFolder = (contentType) => {
  if (contentType == CONTENT_TYPE.FOLDER) {
    return 1;
  } else {
    return 0;
  }
};
// 取消重命名
const handleClickCancelRename = (scope) => {
  let row = scope.row;
  let index = scope.index;
  if (row.id) {
    // 如果存在id，则直接隐藏
    hideEditor(row);
  } else {
    textLibraryList.value.splice(index, 1);
  }
};

// 保存文本库/文件夹
const saveTextLibraryOrFolder = async (params) => {
  let url = "/view/save";
  let res = await httpPost(url, params);
  // if (res.code == 0) {
  //   ElMessage.success("保存成功");
  // } else if (
  //   res.code == ERROR_CODE_NAME_DUPLICATE ||
  //   res.code == PARAM_VALID_ERROR
  // ) {
  //   ElMessage.warning(res.message);
  // }
  return res;
};
//跳转文件夹页面
const goToFolderPage = (row) => {
  router.push({
    name: "TextLibraryIndexWithFolder",
    params: {
      folderId: row.id
    }
  });
};

//跳转文本库页面
const goToLiraryPage = (row) => {
  router.push({
    name: "DataRecordIndex",
    params: {
      textLibraryId: row.id
    }
  });
};

//跳转数据记录页面
const goToDataRecord = (row) => {
  router.push({
    name: "DataRecordIndex",
    params: {
      textLibraryId: row.id
    }
  });
};
const handleClickAnalysis = (row) => {
  router.push({
    name: "AnalysisIndex",
    query: {
      libraryId: row.id
    }
  });
};
// 点击更多
const handleCommand = (row, command) => {
  switch (command) {
    case "copy":
      handleClickCopy(row);
      break;
    case "move":
      handleClickMove(row);
      break;
    case "delete":
      handleClickDeleteTextLibrary(row);
      break;
    case "rename":
      showEditor(row);
      break;
  }
};
// 点击复制（文本库）
const handleClickCopy = (row) => {
  textLibraryDLGTreeSelectRef.value.openDLG({
    type: "COPY",
    copiedLibrary: row
  });
};
// 点击移动（文本库）
const handleClickMove = (row) => {
  textLibraryDLGTreeSelectRef.value.openDLG({
    type: "MOVE",
    movedItemList: [row]
  });
};
// 多选批量操作
const selectedItemList = ref([]); //存储选中item
// 列表勾选变化时，将被选中的item存入selectedItemList
const handleSelectionChange = (val) => {
  selectedItemList.value = val;
};
// 点击批量操作

const handleBatchCommand = (command) => {
  if (selectedItemList.value.length == 0) {
    ElMessage.warning("请先勾选文本库或文件夹！");
    return;
  }
  switch (command) {
    case "batchMove":
      handleClickBatchMove();
      break;
    case "batchDelete":
      handleClickBatchDelete();
      break;
  }
};
// 点击批量移动
const handleClickBatchMove = () => {
  textLibraryDLGTreeSelectRef.value.openDLG({
    type: "MOVE",
    movedItemList: selectedItemList.value
  });
};
// 点击批量删除
const handleClickBatchDelete = () => {
  confirmDeleteBox().then(async () => {
    let params = {
      libraryIdList: selectedItemList.value
        .filter(
          (item) =>
            item.contentType == CONTENT_TYPE.LIBRARY ||
            item.contentType == CONTENT_TYPE.SHARED_LIBRARY
        )
        .map((item) => item.id),
      folderIdList: selectedItemList.value
        .filter((item) => item.contentType == CONTENT_TYPE.FOLDER)
        .map((item) => item.id)
    };
    let res = await deleteTextLibraryOrFolder(params);
    if (res.code == 0) {
      ElMessage.success(res.message);
      getTextLibraryList();
    } else if (
      res.code == DELETE_LIBRARY_CHILD_ERROR ||
      res.code == DELETE_LIBRARY_ANALYSIS_ERROR ||
      res.code == DELETE_LIBRARY_CHILD_AND_ANALYSIS_ERROR
    ) {
      ElMessage.warning(res.message);
    }
  });
};
const deleteTextLibraryOrFolder = async (params) => {
  let url = "/view/delete";
  let res = await httpPost(url, params);
  return res;
};
// 创建文本库弹窗
const textLibraryDLGCreateRef = ref(null);
// 点击创建文本库
const handleClickCreateTextLibrary = () => {
  let newFolder = new Folder({
    parentFolderId: currentFolderId.value,
    ifEdit: true,
    contentType: CONTENT_TYPE.LIBRARY
  });
  textLibraryList.value.unshift(newFolder);
  // let params = {
  //   parentFolderId: currentFolderId.value
  // };
  // textLibraryDLGCreateRef.value.openDLG(params);
};

// 删除文本库确认弹窗
const textLibraryDLGConfirmDeleteRef = ref(null);
//点击删除文本库
const handleClickDeleteTextLibrary = (row) => {
  textLibraryDLGConfirmDeleteRef.value.openDLG({ textLibraryId: row.id });
};
//点击删除文件夹
const handleClickDeleteFolder = async (row) => {
  confirmDeleteBox().then(async () => {
    let params = {
      folderIdList: [row.id]
    };
    let res = await deleteTextLibraryOrFolder(params);
    if (res.code == 0) {
      ElMessage.success(res.message);
      getTextLibraryList();
    } else if (
      res.code == DELETE_LIBRARY_CHILD_ERROR ||
      res.code == DELETE_LIBRARY_ANALYSIS_ERROR ||
      res.code == DELETE_LIBRARY_CHILD_AND_ANALYSIS_ERROR
    ) {
      ElMessage.warning(res.message);
    }
  });
};

// 树形文件夹弹窗
const textLibraryDLGTreeSelectRef = ref(null);

//分页
const paginationState = reactive({
  pageNum: 1,
  pageSize: 10,
  pageSizeList: [10, 20, 50],
  total: 0
});
const handleSizeChange = (val) => {
  paginationState.pageSize = val;
  getTextLibraryList();
};
// 切换当前页码
const handleCurrentChange = (val) => {
  paginationState.pageNum = val;
  getTextLibraryList();
};

// 当前文件夹路径
const folderLevelList = ref(null);
const ROOT_FOLDER = { id: 0, name: "根目录", parentFolderId: null }; //用于显示面包屑
// const currentFolder = computed(() => {
//   if (!folderLevelList.value) {
//     return ROOT_FOLDER;
//   } else {
//     return folderLevelList.value[folderLevelList.value.length - 1];
//   }
// }); //当前文件目录
const currentFolderId = ref(ROOT_FOLDER.id); //用于接受路由参数

// watch(folderLevelList, () => {
//   //TODO
// });

//创建文件夹
const handleClickCreateFolder = () => {
  let newFolder = new Folder({
    parentFolderId: currentFolderId.value,
    ifEdit: true
  });
  textLibraryList.value.unshift(newFolder);
};

const init = () => {
  currentFolderId.value = route.params.folderId || ROOT_FOLDER.id;
};
onMounted(() => {
  init();
  getTextLibraryList();
});
</script>

<style lang="scss" scoped>
@import "@/assets/styles/components/list-table";
.table-wrapper {
  .library-name {
    @include pointer();
    @include no-selectable();
    .icon-fill-folder {
      color: $folder-icon-color;
    }
    .icon-textLibrary {
      color: $theme-color;
    }
  }
}
</style>
