<!-- TODO 待测试 -->
<template>
  <div>
    <PageTitle :title="title" />
    <LogTable />
  </div>
</template>

<script setup>
import { defineEmits, ref, onMounted } from "vue";
import { useRoute } from "vue-router";
import PageTitle from "@/components/base/PageTitle.vue";
import LogTable from "./components/LogTable.vue";
import {
  getFolderLevelListByTextLibraryId,
  getFolderBreadcrumbList
} from "./api/getFolderLevelList";
const title = "查看日志";
const route = useRoute();
const emits = defineEmits(["setBreadcrumbList"]);
const folderLevelList = ref([]);
const textLibraryId = route.params.textLibraryId;
const getFolderLevelList = async () => {
  folderLevelList.value = await getFolderLevelListByTextLibraryId(
    textLibraryId
  );
};
onMounted(async () => {
  await getFolderLevelList();
  let breadcrumblist = getFolderBreadcrumbList(folderLevelList.value);
  breadcrumblist.push(
    {
      title: "数据记录",
      to: {
        name: "DataRecordIndex",
        params: {
          textLibraryId: textLibraryId
        }
      }
    },
    {
      title: title
    }
  );
  emits("setBreadcrumbList", breadcrumblist);
});
</script>

<style lang="scss" scoped></style>
