<template>
  <div
    ref="statisticRef"
    style="min-height: 480px"
    class="quick-statistics-wrapper"
  >
    <div v-if="ifShowChart">
      <div
        class="record-statistic-box"
        v-for="(value, key, index) in state.statisticList"
        :key="key"
      >
        <!-- 条件选择 -->
        <div class="selection-wrapper">
          <div class="name">{{ key }}</div>
          <!-- 分类文本 -->
          <div class="selection">
            <!-- 前～ -->
            <el-select
              v-model="value.count"
              size="small"
              v-show="
                value.chartType == 1 ||
                value.chartType == 2 ||
                value.chartType == 3 ||
                value.chartType == 4
              "
              @change="
                (val) => {
                  changeCount(val, key, value.chartType, index);
                }
              "
            >
              <el-option
                v-for="item in options.catTextOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
            <!-- 按年月日 -->
            <el-select
              v-model="value.timeType"
              size="small"
              v-show="value.chartType == 11"
              @change="
                (val) => {
                  changeTimeLineChartInfoList(val, key, index);
                }
              "
            >
              <el-option
                v-for="item in options.dateTimeOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
            <!-- 日期范围 -->
            <el-input
              v-model="value.beginYear"
              placeholder="请输入年份"
              size="small"
              v-show="value.chartType == 11"
              @blur="changeTimeLineChartInfoList(value.timeType, key, index)"
            />
            <span v-show="value.chartType == 11">-</span>
            <el-input
              class="year"
              v-model="value.endYear"
              placeholder="请输入年份"
              size="small"
              v-show="value.chartType == 11"
              @blur="changeTimeLineChartInfoList(value.timeType, key, index)"
            />

            <!-- 1/2/3/4类型 -->
            <el-select
              v-model="value.chartType"
              size="small"
              v-show="
                value.chartType == 1 ||
                value.chartType == 2 ||
                value.chartType == 3 ||
                value.chartType == 4
              "
              @change="
                (val) => {
                  changeType(val, key, index);
                }
              "
            >
              <el-option
                v-for="item in options.catTextChartOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
            <!-- 6/7类型 -->
            <el-select
              v-model="value.chartType"
              size="small"
              v-show="value.chartType == 6 || value.chartType == 7"
              @change="
                (val) => {
                  changeType(val, key, index);
                }
              "
            >
              <el-option
                v-for="item in options.numChartOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
            <!-- 11类型 -->
            <el-select
              v-model="value.chartType"
              size="small"
              v-show="value.chartType == 11"
            >
              <el-option
                v-for="item in options.dateTimeChartOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
            <!-- 导出 -->
            <el-select
              v-model="value.exportType"
              size="small"
              @change="
                (val) => {
                  changeExportType(val, value.chartType, index);
                }
              "
            >
              <el-option
                v-for="item in options.exportOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </div>
        </div>
        <!-- 图形 -->
        <el-row>
          <!-- 柱状图:2 -->
          <el-col :span="24" v-show="value.chartType == 2">
            <BarChart
              :ref="setRefBarChart"
              :chartInfo="state.barChartInfoList[index]"
              type="vertical"
            />
          </el-col>
          <!-- 条形图:1 -->
          <el-col :span="24" v-show="value.chartType == 1">
            <BarChart
              :ref="setRefHorizontalBarChart"
              :chartInfo="state.horizontalBarChartInfoList[index]"
              type="horizontal"
            />
          </el-col>
          <!-- 饼状图:3 -->
          <el-col :span="24" v-show="value.chartType == 3">
            <PieChart
              :ref="setRefPieChart"
              :chartInfo="state.pieChartInfoList[index]"
            />
          </el-col>
          <!-- 折线图:4 -->
          <el-col :span="24" v-show="value.chartType == 4">
            <LineChart
              :ref="setRefLineChart"
              :chartInfo="state.lineChartInfoList[index]"
            />
          </el-col>
          <!-- 正态图:6 -->
          <el-col :span="24" v-show="value.chartType == 6">
            <BarLineChart
              :ref="setRefBarLineChart"
              :chartInfo="state.barLineChartInfoList[index]"
            />
          </el-col>
          <!-- 箱线图:7 -->
          <el-col :span="24" v-show="value.chartType == 7">
            <BoxPlotChart
              :ref="setRefBoxPlotChart"
              :chartInfo="state.boxPlotChartInfoList[index]"
            />
          </el-col>
          <!-- 时间折线图:11 -->
          <el-col :span="24" v-show="value.chartType == 11">
            <TimeLineChart
              :ref="setRefTimeLineChart"
              :chartInfo="state.timeLineChartInfoList[index]"
            />
          </el-col>
        </el-row>
      </div>
    </div>
    <div v-else>
      <EmptyStatus content="当前没有支持快速统计的变量" />
    </div>
  </div>
</template>

<script setup>
import {
  reactive,
  ref,
  defineProps,
  defineEmits,
  onMounted,
  nextTick,
  computed
} from "vue";
import { useRouter, useRoute } from "vue-router";
import { ElMessage, ElLoading } from "element-plus";
import { httpPost } from "@/api/httpService.js";
import BarChart from "@/components/chart/BarChart.vue";
import PieChart from "@/components/chart/PieChart.vue";
import LineChart from "@/components/chart/LineChart.vue";
import BarLineChart from "@/components/chart/BarLineChart.vue";
import BoxPlotChart from "@/components/chart/BoxPlotChart.vue";
import TimeLineChart from "@/components/chart/TimeLineChart.vue";
import EmptyStatus from "@/components/base/EmptyStatus.vue";
const refBarChart = ref([]);
const refHorizontalBarChart = ref([]);
const refPieChart = ref([]);
const refLineChart = ref([]);
const refBarLineChart = ref([]);
const refBoxPlotChart = ref([]);
const refTimeLineChart = ref([]);
const statisticRef = ref(null);
const setRefBarChart = (el) => {
  refBarChart.value.push(el);
};
const setRefHorizontalBarChart = (el) => {
  refHorizontalBarChart.value.push(el);
};
const setRefPieChart = (el) => {
  refPieChart.value.push(el);
};
const setRefLineChart = (el) => {
  refLineChart.value.push(el);
};
const setRefBarLineChart = (el) => {
  refBarLineChart.value.push(el);
};
const setRefBoxPlotChart = (el) => {
  refBoxPlotChart.value.push(el);
};
const setRefTimeLineChart = (el) => {
  refTimeLineChart.value.push(el);
};
const route = useRoute();
const router = useRouter();
let libraryId = route.params.textLibraryId;
/**数据 start */
let barChart = ref(null);
let horizontalBarChart = ref(null);
let state = ref({
  libraryId: null,
  statisticList: {},
  value1: 1,
  input: "",
  // exportType: null,
  catTextChartValue: null,
  dateTimeChartValue: null,
  numChartValue: null,
  // barChartInfo: {},
  // horizontalBarChartInfo: {}
  barChartInfoList: [],
  horizontalBarChartInfoList: [],
  lineInfoList: [],
  pieChartInfoList: [],
  lineChartInfoList: [],
  barLineChartInfoList: [],
  boxPlotChartInfoList: [],
  timeLineChartInfoList: []
});
let options = reactive({
  exportOptions: [
    {
      value: "png",
      label: "导出PNG"
    },
    {
      value: "jpg",
      label: "导出JPG"
    }
  ],
  catTextChartOptions: [
    {
      value: 1,
      label: "条形图"
    },
    {
      value: 2,
      label: "柱状图"
    },

    {
      value: 4,
      label: "折线图"
    },
    {
      value: 3,
      label: "饼状图"
    }
  ],
  catTextOptions: [
    {
      value: 1,
      label: "前5"
    },
    {
      value: 2,
      label: "前10"
    },
    {
      value: 3,
      label: "前20"
    }
  ],
  dateTimeChartOptions: [
    {
      value: 11,
      label: "折线图"
    }
  ],
  dateTimeOptions: [
    {
      value: 1,
      label: "按年"
    },
    {
      value: 2,
      label: "按月"
    },
    {
      value: 3,
      label: "按日"
    }
  ],
  numChartOptions: [
    {
      value: 6,
      label: "正态分布图"
    },
    {
      value: 7,
      label: "箱线图"
    }
  ]
});
const getAllStatistic = async () => {
  let res = await httpPost(`/lib/record/get/all/statistic/${libraryId}`);
  if (res.code == 0) {
    loadingInstance.close();
    for (let key in res.data) {
      if (res.data[key].chartType != 0) {
        state.value.statisticList[key] = res.data[key];
        state.value.statisticList[key].exportType = null;
        state.value.statisticList[key].count = 1;
        state.value.statisticList[key].timeType = 1;
        state.value.statisticList[key].beginYear = null;
        state.value.statisticList[key].endYear = null;
      }
    }
    state.value.barChartInfoList = [];
    state.value.horizontalBarChartInfoList = [];
    state.value.lineInfoList = [];
    state.value.pieChartInfoList = [];
    state.value.lineChartInfoList = [];
    state.value.barLineChartInfoList = [];
    state.value.boxPlotChartInfoList = [];
    state.value.timeLineChartInfoList = [];
    for (let key in state.value.statisticList) {
      if (
        state.value.statisticList[key].chartType == 1 ||
        state.value.statisticList[key].chartType == 2 ||
        state.value.statisticList[key].chartType == 3 ||
        state.value.statisticList[key].chartType == 4
      ) {
        //支持柱状图、条形图、折线图、饼状图
        var colors = [
          "#3fb1e3",
          "#3b7af5",
          "#5678f5",
          "#1c29a5",
          "#389fbf",
          "#96dee8",
          "#215460"
        ];
        var colNames = state.value.statisticList[key].chartData.categoryList;
        var data = state.value.statisticList[key].chartData.categoryDataList;
        var barData = [];
        var pieData = [];
        var lineData = [];
        for (var i = 0; i < colNames.length; i++) {
          barData.push({
            value: data[i],
            itemStyle: {
              color: colors[i % colors.length]
            }
          });
          pieData.push({
            value: data[i],
            name: colNames[i],
            itemStyle: {
              color: colors[i % colors.length]
            }
          });
          lineData.push({
            value: data[i],
            itemStyle: {
              color: colors[i % colors.length]
            }
          });
        }
        state.value.statisticList[key].colNames_5 = colNames.slice(0, 5);
        state.value.statisticList[key].colNames_10 = colNames.slice(0, 10);
        state.value.statisticList[key].colNames_20 = colNames.slice(0, 20);
        state.value.statisticList[key].barData_5 = barData.slice(0, 5);
        state.value.statisticList[key].barData_10 = barData.slice(0, 10);
        state.value.statisticList[key].barData_20 = barData.slice(0, 20);
        state.value.statisticList[key].pieData_5 = pieData.slice(0, 5);
        state.value.statisticList[key].pieData_10 = pieData.slice(0, 10);
        state.value.statisticList[key].pieData_20 = pieData.slice(0, 20);
        state.value.statisticList[key].lineData_5 = lineData.slice(0, 5);
        state.value.statisticList[key].lineData_10 = lineData.slice(0, 10);
        state.value.statisticList[key].lineData_20 = lineData.slice(0, 20);
        state.value.barChartInfoList.push({
          title: key + "柱状图",
          legendNames: state.value.statisticList[key].colNames_5,
          colNames: state.value.statisticList[key].colNames_5,
          xName: key,
          yName: "",
          data: state.value.statisticList[key].barData_5
        });
        state.value.horizontalBarChartInfoList.push({
          title: key + "条形图",
          legendNames: state.value.statisticList[key].colNames_5,
          colNames: state.value.statisticList[key].colNames_5,
          xName: key,
          yName: "",
          data: state.value.statisticList[key].barData_5
        });
        state.value.pieChartInfoList.push({
          title: key + "饼状图",
          colNames: state.value.statisticList[key].colNames_5,
          xName: key,
          radius: "60%",
          data: state.value.statisticList[key].pieData_5
        });
        state.value.lineChartInfoList.push({
          title: key + "折线图",
          legendNames: state.value.statisticList[key].colNames_5,
          colNames: state.value.statisticList[key].colNames_5,
          xName: key,
          yName: "",
          data: state.value.statisticList[key].lineData_5
        });
        state.value.barLineChartInfoList.push({});
        state.value.boxPlotChartInfoList.push({});
        state.value.timeLineChartInfoList.push({});
        nextTick(() => {
          var i = 0;
          for (var key in state.value.statisticList) {
            if (state.value.statisticList[key].chartType == 1) {
              refHorizontalBarChart.value[i].initChartView();
            } else if (state.value.statisticList[key].chartType == 2) {
              refBarChart.value[i].initChartView();
            } else if (state.value.statisticList[key].chartType == 3) {
              refPieChart.value[i].initChartView();
            } else if (state.value.statisticList[key].chartType == 4) {
              refLineChart.value[i].initChartView();
            }
            i++;
          }
        });
      } else if (
        state.value.statisticList[key].chartType == 6 ||
        state.value.statisticList[key].chartType == 7
      ) {
        state.value.barChartInfoList.push({});
        state.value.horizontalBarChartInfoList.push({});
        state.value.pieChartInfoList.push({});
        state.value.lineChartInfoList.push({});
        state.value.barLineChartInfoList.push({
          title: key + "正态分布图",
          barYName: "区间频率",
          lineYName: "理想正态分布",
          lineLegendName: "正态分布曲线",
          barLegendName: key,
          xName: key,
          barData: state.value.statisticList[key].chartData["正态图"].barData,
          lineData: state.value.statisticList[key].chartData["正态图"].lineData
        });
        state.value.boxPlotChartInfoList.push({
          title: key + "箱线图",
          dataSource: [state.value.statisticList[key].chartData["箱线图"].data], //二维数组
          outliersColor: "#D3CD97",
          boxplotColor: "grey",
          borderColor: "black",
          xName: "",
          yName: "",
          labels: [
            state.value.statisticList[key].chartData["箱线图"].fieldLabelList
          ] //类目名
        });
        state.value.timeLineChartInfoList.push({});
        nextTick(() => {
          var i = 0;
          for (var key in state.value.statisticList) {
            if (state.value.statisticList[key].chartType == 6) {
              refBarLineChart.value[i].initChartView();
            } else if (state.value.statisticList[key].chartType == 7) {
              refBoxPlotChart.value[i].initChartView();
            }
            i++;
          }
        });
      } else if (state.value.statisticList[key].chartType == 11) {
        state.value.barChartInfoList.push({});
        state.value.horizontalBarChartInfoList.push({});
        state.value.pieChartInfoList.push({});
        state.value.lineChartInfoList.push({});
        state.value.barLineChartInfoList.push({});
        state.value.boxPlotChartInfoList.push({});
        state.value.timeLineChartInfoList.push({
          title: key + "时间折线图",
          // dataZoom: {
          startValue: null,
          endValue: null,
          // },
          dataList: state.value.statisticList[key].chartData.year.xaxisList,
          countList: state.value.statisticList[key].chartData.year.yaxisList
        });
        nextTick(() => {
          var i = 0;
          for (var key in state.value.statisticList) {
            refTimeLineChart.value[i].initChartView();
            i++;
          }
        });
      }
    }
  }
};
const changeExportType = (val, chartType, index) => {
  if (chartType == 1) {
    refHorizontalBarChart.value[index].exportChart(val);
  } else if (chartType == 2) {
    refBarChart.value[index].exportChart(val);
  } else if (chartType == 3) {
    refPieChart.value[index].exportChart(val);
  } else if (chartType == 4) {
    refLineChart.value[index].exportChart(val);
  } else if (chartType == 6) {
    refBarLineChart.value[index].exportChart(val);
  } else if (chartType == 7) {
    refBoxPlotChart.value[index].exportChart(val);
  } else if (chartType == 11) {
    refTimeLineChart.value[index].exportChart(val);
  }
};
const changeType = (val, key, index) => {
  if (val == 1) {
    refHorizontalBarChart.value[index].setChartView();
  } else if (val == 2) {
    refBarChart.value[index].setChartView();
  } else if (val == 3) {
    refPieChart.value[index].setChartView();
  } else if (val == 4) {
    refLineChart.value[index].setChartView();
  } else if (val == 6) {
    refBarLineChart.value[index].setChartView();
  } else if (val == 7) {
    refBoxPlotChart.value[index].setChartView();
  }
  // else if (val == 11) {
  //   refTimeLineChart.value[index].setChartView();
  // }
  state.value.statisticList[key].count = 1;
};

const changeCount = (val, key, chartType, index) => {
  if (chartType == 1) {
    if (val == 1) {
      state.value.horizontalBarChartInfoList.splice(index, 1, {
        title: key + "条形图",
        legendNames: state.value.statisticList[key].colNames_5,
        colNames: state.value.statisticList[key].colNames_5,
        xName: key,
        yName: "",
        data: state.value.statisticList[key].barData_5
      });
    } else if (val == 2) {
      state.value.horizontalBarChartInfoList.splice(index, 1, {
        title: key + "条形图",
        legendNames: state.value.statisticList[key].colNames_10,
        colNames: state.value.statisticList[key].colNames_10,
        xName: key,
        yName: "",
        data: state.value.statisticList[key].barData_10
      });
    } else if (val == 3) {
      state.value.horizontalBarChartInfoList.splice(index, 1, {
        title: key + "条形图",
        legendNames: state.value.statisticList[key].colNames_20,
        colNames: state.value.statisticList[key].colNames_20,
        xName: key,
        yName: "",
        data: state.value.statisticList[key].barData_20
      });
    }
    refHorizontalBarChart.value[index].setChartView();
  } else if (chartType == 2) {
    if (val == 1) {
      state.value.barChartInfoList.splice(index, 1, {
        title: key + "柱状图",
        legendNames: state.value.statisticList[key].colNames_5,
        colNames: state.value.statisticList[key].colNames_5,
        xName: key,
        yName: "",
        data: state.value.statisticList[key].barData_5
      });
    } else if (val == 2) {
      state.value.barChartInfoList.splice(index, 1, {
        title: key + "柱状图",
        legendNames: state.value.statisticList[key].colNames_10,
        colNames: state.value.statisticList[key].colNames_10,
        xName: key,
        yName: "",
        data: state.value.statisticList[key].barData_10
      });
    } else if (val == 3) {
      state.value.barChartInfoList.splice(index, 1, {
        title: key + "柱状图",
        legendNames: state.value.statisticList[key].colNames_20,
        colNames: state.value.statisticList[key].colNames_20,
        xName: key,
        yName: "",
        data: state.value.statisticList[key].barData_20
      });
    }
    refBarChart.value[index].setChartView();
  } else if (chartType == 3) {
    if (val == 1) {
      state.value.pieChartInfoList.splice(index, 1, {
        title: key + "饼状图",
        colNames: state.value.statisticList[key].colNames_5,
        xName: key,
        radius: "60%",
        data: state.value.statisticList[key].pieData_5
      });
    } else if (val == 2) {
      state.value.pieChartInfoList.splice(index, 1, {
        title: key + "饼状图",
        xName: key,
        radius: "60%",
        data: state.value.statisticList[key].pieData_10
      });
    } else if (val == 3) {
      state.value.pieChartInfoList.splice(index, 1, {
        title: key + "饼状图",
        colNames: state.value.statisticList[key].colNames_20,
        xName: key,
        radius: "60%",
        data: state.value.statisticList[key].pieData_20
      });
    }
    refPieChart.value[index].setChartView();
  } else if (chartType == 4) {
    if (val == 1) {
      state.value.lineChartInfoList.splice(index, 1, {
        title: key + "折线图",
        legendNames: state.value.statisticList[key].colNames_5,
        colNames: state.value.statisticList[key].colNames_5,
        xName: key,
        yName: "",
        data: state.value.statisticList[key].lineData_5
      });
    } else if (val == 2) {
      state.value.lineChartInfoList.splice(index, 1, {
        title: key + "折线图",
        legendNames: state.value.statisticList[key].colNames_10,
        colNames: state.value.statisticList[key].colNames_10,
        xName: key,
        yName: "",
        data: state.value.statisticList[key].lineData_10
      });
    } else if (val == 3) {
      state.value.lineChartInfoList.splice(index, 1, {
        title: key + "折线图",
        legendNames: state.value.statisticList[key].colNames_10,
        colNames: state.value.statisticList[key].colNames_20,
        xName: key,
        yName: "",
        data: state.value.statisticList[key].lineData_20
      });
    }
    refLineChart.value[index].setChartView();
  }
};
const changeTimeLineChartInfoList = (val, key, index) => {
  let title = key + "时间折线图";
  let startValue;
  let endValue;
  let dataList;
  let countList;
  if (val == 1) {
    //年
    dataList = state.value.statisticList[key].chartData.year.xaxisList;
    countList = state.value.statisticList[key].chartData.year.yaxisList;
  } else if (val == 2) {
    //月
    dataList = state.value.statisticList[key].chartData.month.xaxisList;
    countList = state.value.statisticList[key].chartData.month.yaxisList;
  } else if (val == 3) {
    //日
    dataList = state.value.statisticList[key].chartData.day.xaxisList;
    countList = state.value.statisticList[key].chartData.day.yaxisList;
  }
  let beginYear = state.value.statisticList[key].beginYear;
  let endYear = state.value.statisticList[key].endYear;
  let reg = /^\d{4}$/;
  let beginFlag = false;
  let endFlag = false;
  if (reg.test(beginYear) || beginYear == "" || beginYear == null) {
    beginFlag = true;
    if (beginYear == "") {
      beginYear = null;
    }
  } else {
    ElMessage.warning("请输入正确的年份");
    return;
  }
  if (reg.test(endYear) || endYear == "" || endYear == null) {
    endFlag = true;
    if (endYear == "") {
      endYear = null;
    }
  } else {
    ElMessage.warning("请输入正确的年份");
    return;
  }
  if (beginFlag && endFlag) {
    if (val == 1) {
      startValue = beginYear;
      endValue = endYear;
    } else if (val == 2 || val == 3) {
      if (beginYear) {
        startValue = findCloseTime(key, val, beginYear, "begin");
      } else {
        startValue = null;
      }
      if (endYear) {
        endValue = findCloseTime(key, val, endYear, "end");
      } else {
        endValue = null;
      }
    }
  }
  state.value.timeLineChartInfoList.splice(index, 1, {
    title: title,
    startValue: startValue,
    endValue: endValue,
    dataList: dataList,
    countList: countList
  });
  refTimeLineChart.value[index].setChartView();
};
const findCloseTime = (key, timeType, time, type) => {
  let item = state.value.statisticList[key];
  let monthList;
  let dayList;
  if (type == "begin") {
    monthList = item.chartData.month.xaxisList;
    dayList = item.chartData.day.xaxisList;
  } else if (type == "end") {
    monthList = JSON.parse(
      JSON.stringify(item.chartData.month.xaxisList)
    ).reverse();
    dayList = JSON.parse(
      JSON.stringify(item.chartData.day.xaxisList)
    ).reverse();
  }
  if (timeType == 2) {
    let res = monthList.find((v) => {
      return v.includes(time);
    });
    return res;
  } else if (timeType == 3) {
    let res = dayList.find((v) => {
      return v.includes(time);
    });
    return res;
  }
};
let loadingInstance = null;
// 是否显示图表
const ifShowChart = computed(() => {
  return JSON.stringify(state.value.statisticList) != "{}";
});
/**数据 end */
onMounted(() => {
  nextTick(() => {
    // console.log("staticref", statisticRef.value);
  });
  loadingInstance = ElLoading.service({
    target: statisticRef.value,
    fullscreen: true
  });
  getAllStatistic();
});
</script>

<style lang="scss" scoped>
.quick-statistics-wrapper {
  display: flex;
  flex-direction: column;
  .record-statistic-box {
    width: 100%;
    height: 480px;
    margin-bottom: 20px;
    @include default-border();
    padding: 10px;
    @include round-corner(5px);
    .selection-wrapper {
      display: flex;
      justify-content: space-between;
      line-height: 30px;
      .name {
        padding-left: 8px;
        border-left: 12px solid $primary-color;
      }
      .selection {
        .el-select {
          margin-right: 15px;
        }
        .el-select:last-child {
          margin-right: 0;
        }
        .el-input {
          width: 120px;
        }
        .el-input.year {
          margin-right: 15px;
        }
        & > span {
          margin: 0 5px;
        }
      }
    }
  }
}
</style>
