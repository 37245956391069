<template>
  <div class="from-ring-data-container">
    <p class="message-box">
      在锐研数据平台搜索相应的新闻、政策、法律、文书、专利、论文等结果，选择所需要分析的数据，在检索结果页面右下角对话框点击添加到文本库，数据字段将自动映射到对应目标变量。当目标变量已经存在的情况下，从锐研数据平台导入数据仅会导入变量名一致的字段。如图所示：
    </p>
    <img class="img-box" src="@/assets/imgs/scd.png" alt="" />
  </div>
</template>

<script setup></script>

<style lang="scss" scoped>
.from-ring-data-container {
  margin: 30px;
  .message-box {
    color: #777;
    line-height: 24px;
    margin-bottom: 20px;
  }
  .img-box {
    width: 100%;
  }
}
</style>
