<!-- 文本库-数据操作-替换弹窗 -->
<template>
  <el-dialog
    v-model="DLGVisible"
    :title="DLGTitle"
    :before-close="closeDLG"
    align-center
    class="base-dialog"
  >
    <template #header>
      <BaseDialogTitle title="替换" :item-name="textLibraryName" />
    </template>

    <el-form ref="formRef" :model="form" :rules="formRules">
      <el-form-item prop="repalceVariable" label="替换变量：">
        <TagSelector
          v-model:data="form.variableList"
          label-prop="fieldLabel"
          value-prop="fieldName"
          @change="onChangeTag"
        ></TagSelector>
      </el-form-item>
      <el-form-item prop="oldText" label="原始文本：">
        <el-input v-model="form.oldText" placeholder=""></el-input>
      </el-form-item>
      <el-form-item prop="newText" label="替换文本：">
        <el-input v-model="form.newText" placeholder=""></el-input>
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="closeDLG">取消</el-button>
        <el-button
          type="primary"
          @click="handleClickConfirm"
          :disabled="saveButtonDisable"
        >
          替换
        </el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script setup>
import { reactive, ref, defineProps, defineEmits, defineExpose } from "vue";
import { httpPost } from "@/api/httpService.js";
import { ElMessage } from "element-plus";
import BaseDialogTitle from "@/components/base/BaseDialogTitle.vue";
import TagSelector from "@/components/selector/TagSelector.vue";
import { getFolderLevelListByTextLibraryId } from "../api/getFolderLevelList";

// 获取文本库名称
const textLibraryName = ref("");
const folderLevelList = ref([]);
const getFolderLevelList = async () => {
  folderLevelList.value = await getFolderLevelListByTextLibraryId(libraryId);
  textLibraryName.value =
    folderLevelList.value[folderLevelList.value.length - 1].name;
};

const saveButtonDisable = ref(false);
const showSaveButtonDisable = () => {
  saveButtonDisable.value = true;
};
const hideSaveButtonDisable = () => {
  saveButtonDisable.value = false;
};
const emits = defineEmits(["refresh"]);
// 弹窗相关
const DLGVisible = ref(false);
const DLGTitle = ref("");
let libraryId = "";
const openDLG = (params) => {
  DLGVisible.value = true;
  libraryId = params.libraryId;
  getVariableList();
  getFolderLevelList();
};
// 根据id获取变量
const getVariableList = async () => {
  let res = await httpPost(`/lib/field/list/${libraryId}`);
  if (res.code == 0) {
    form.value.variableList = res.data;
  }
};

const closeDLG = () => {
  DLGVisible.value = false;
  resetForm();
};
// 点击确认
const handleClickConfirm = () => {
  formRef.value.validate(async (valid) => {
    let fieldNameList = [];
    form.value.variableList.forEach((_) => {
      if (_.checked) {
        fieldNameList.push(_.fieldName);
      }
    });
    if (valid) {
      showSaveButtonDisable();
      let params = {
        libraryId: libraryId,
        fieldNameList: fieldNameList,
        fromStr: form.value.oldText,
        toStr: form.value.newText
      };
      let res = await httpPost(`/lib/record/update/replace`, params);
      if (res.code == 0) {
        ElMessage.success("替换成功");
        emits("refresh");
        closeDLG();
        // initTableContent("father");
        // dialogClearState.dialogClearVisible = false;
      } else {
        ElMessage.warning(res.message);
      }
    } else {
      console.log("替换失败");
      // return false;
    }
    hideSaveButtonDisable();
  });
};
// 表格相关
const form = ref({});
const formRef = ref(null);

// 替换变量验证方法
const validateVariableList = (rule, value, callback) => {
  let bollean = form.value.variableList.find((_) => _.checked);
  console.log(bollean, "bollean");
  if (bollean) {
    callback();
  } else {
    callback(new Error("请至少选择一个变量"));
  }
};
const formRules = ref({
  repalceVariable: [
    { required: true, validator: validateVariableList, trigger: "change" }
  ],
  newText: [
    {
      required: true,
      message: "替换文本不能为空",
      trigger: "change"
    }
  ],
  oldText: [
    {
      required: true,
      message: "原始文本不能为空",
      trigger: "change"
    }
  ]
});

// 重置表格
const resetForm = () => {
  formRef.value.resetFields();
};

const onChangeTag = () => {
  console.log("onChangeTag", form.value.variableList);
};

defineExpose({ openDLG, closeDLG });
</script>
<style lang="scss" scoped></style>
