<template>
  <div class="list-table">
    <div class="option-bar" v-if="ifVariablePage">
      <div class="search-box"></div>
      <div class="button-group">
        <el-button @click="handleClickGoBack" link type="primary">
          <i
            class="iconfont icon-fenximoxing-chexiao"
            style="margin-right: 4px"
          ></i>
          返回
        </el-button>
        <el-button
          link
          type="primary"
          @click="handleClickAddVariable"
          v-if="!ifSystem"
        >
          <i class="iconfont icon-plus-circle" style="margin-right: 4px"></i>
          新增变量
        </el-button>
        <el-button link type="primary" @click="exportVariable" v-if="!ifSystem">
          <i
            class="iconfont icon-daochu"
            style="font-size: 18px; margin-right: 4px"
          ></i>
          导出
        </el-button>
        <el-button
          type="primary"
          @click="handleClickSaveVariable"
          link
          v-if="!ifSystem"
        >
          <i
            class="iconfont icon-fenximoxing-baocun"
            style="margin-right: 4px"
          ></i>
          保存
        </el-button>
      </div>
    </div>
    <el-table
      :data="variableTableList"
      style="width: 100%"
      @selection-change="handleSelectionChange"
      v-loading="tableLoading"
      header-cell-class-name="list-table-header"
      max-height="500"
      min-height="500"
      :default-sort="{ prop: 'sort', order: 'descending' }"
    >
      <el-table-column type="selection" width="55" />
      <el-table-column
        type="index"
        label="序号"
        width="60"
        v-if="ifVariablePage"
      />
      <el-table-column v-else label="导入字段">
        <template #default="scope">
          {{ scope.row.importVariable }}
        </template>
      </el-table-column>
      <el-table-column prop="fieldName" label="变量名称">
        <template #header>
          变量名称
          <el-tooltip content="变量的名称，通常用英文">
            <i class="iconfont icon-wenhao"></i>
          </el-tooltip>
        </template>
        <template #default="scope">
          <el-input
            :disabled="ifSystem"
            v-if="scope.row.ifEdit || type == 'IMPORT_FROM_FILE'"
            v-model.trim="scope.row.fieldName"
            @change="(value) => changeFieldName(value, scope.row)"
            placeholder="请输入"
            clearable
            required
          />
          <span v-else>
            {{ scope.row.fieldName }}
          </span>
        </template>
      </el-table-column>
      <el-table-column prop="fieldLabel" label="变量标签">
        <template #header>
          变量标签
          <el-tooltip content="描述变量的标签，通常用中文">
            <i class="iconfont icon-wenhao"></i>
          </el-tooltip>
        </template>
        <template #default="scope">
          <el-input
            :disabled="ifSystem"
            v-model.trim="scope.row.fieldLabel"
            placeholder="请输入"
            clearable
          />
        </template>
      </el-table-column>
      <el-table-column prop="type" label="变量类型">
        <template #header>
          变量类型
          <el-tooltip>
            <i class="iconfont icon-wenhao"></i>
            <template #content>
              文本：有最大长度限制的短文本，如新闻标题、论文摘要、微博正文等
              <br />

              长文本：较长的文本，如新闻正文、论文正文等。
              <br />
              分类文本：
              <br />
              时间：年月日、时分秒等日期型数据，如发布时间、采集时间等。
              <br />
              数值：通常是用实数表示的数据类型，如身高、温度等。
              <br />
              链接：存储链接形式的数据。
              <br />
            </template>
          </el-tooltip>
        </template>
        <template #default="scope">
          <el-select
            :disabled="ifSystem"
            v-model="scope.row.type"
            class="m-2"
            placeholder="请选择"
            @change="(type) => handleChangeSelect(type, scope.row)"
          >
            <el-option
              v-for="item in VARIABLE_OPTION_LIST"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </template>
      </el-table-column>
      <el-table-column prop="language" label="语言">
        <template #default="scope">
          <el-select
            :disabled="ifSystem"
            v-model="scope.row.language"
            v-if="scope.row.type == TEXT || scope.row.type == LONG_TEXT"
            class="m-2"
            placeholder="请选择"
            @change="(type) => handleChangeSelect(type, scope.row)"
          >
            <el-option
              v-for="item in LANGUAGE_LIST"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
          <span v-else>--</span>
        </template>
      </el-table-column>
      <el-table-column prop="decimal" label="小数位数">
        <template #default="scope">
          <el-input
            :disabled="ifSystem"
            v-if="scope.row.type == NUMBER"
            v-model.trim="scope.row.decimal"
            placeholder="请输入"
            clearable
          />
          <span v-else>--</span>
        </template>
      </el-table-column>
      <el-table-column prop="dateFormat" label="格式">
        <template #default="scope">
          <el-select
            :disabled="ifSystem"
            v-model="scope.row.dateFormat"
            class="m-2"
            placeholder="请选择"
            v-if="scope.row.type == DATE"
          >
            <el-option
              v-for="item in DATAFORMAT_OPTION_LIST"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
          <span v-else>--</span>
        </template>
      </el-table-column>
      <el-table-column label="不允许空值">
        <template #default="scope">
          <el-switch
            :disabled="ifSystem"
            v-model="scope.row.ifPermitNull"
            :active-value="1"
            :inactive-value="0"
          />
        </template>
      </el-table-column>
      <el-table-column v-if="ifVariablePage" label="列表显示">
        <template #header>
          列表显示

          <el-tooltip
            content="变量的列表显示功能开启后，导入的变量会在数据记录列表上显示"
          >
            <i class="iconfont icon-wenhao"></i>
          </el-tooltip>
        </template>
        <template #default="scope">
          <el-switch
            :disabled="ifSystem"
            v-model="scope.row.ifVisualizable"
            :active-value="1"
            :inactive-value="0"
          />
        </template>
      </el-table-column>
      <el-table-column v-else label="是否忽略">
        <template #default="scope">
          <el-switch
            v-model="scope.row.ifIgnore"
            :active-value="1"
            :inactive-value="0"
          />
        </template>
      </el-table-column>
      <el-table-column
        v-if="ifVariablePage && !ifSystem"
        label="操作"
        width="150"
      >
        <template #default="scope">
          <div class="list-item-btn">
            <!-- <el-button link type="primary">
              <i class="iconfont icon-tuozhuai"></i>
            </el-button> -->
            <el-button
              link
              type="primary"
              @click="handleClickDeleteVariable(scope.row)"
            >
              <i class="iconfont icon-shanchu1"></i>
            </el-button>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <!-- <div v-if="ifVariablePage" class="operation-btn-box">
      <el-button type="primary" @click="handleClickAddVariable">
        新增变量
      </el-button>
      <el-button>导出</el-button>
    </div> -->
  </div>
  <!-- <div v-if="ifVariablePage" class="variable-btn-box">
    <el-button @click="handleClickGoBack">取消</el-button>
    <el-button type="primary" @click="handleClickSaveVariable">保存</el-button>
  </div> -->
</template>

<script setup>
import { ref, defineProps, defineEmits, defineExpose, computed } from "vue";
import { useRoute, useRouter } from "vue-router";
import { httpPost } from "@/api/httpService.js";
import { ElMessage, ElMessageBox } from "element-plus";
import { export2Excel } from "@/utils/excel.js";
import {
  DATAFORMAT_OPTION_LIST,
  VARIABLE_OPTION_LIST,
  DATE,
  TEXT,
  LONG_TEXT,
  NUMBER,
  LANGUAGE_LIST
} from "@/constant/variable.js";
import { Debounce } from "@/utils/utils";
import { SYSTEM_USER_ID } from "@/constant/system";

const route = useRoute();
const router = useRouter();
const props = defineProps({
  variableTableList: Array,
  type: String,
  info: {
    type: Object,
    default: () => {
      return {};
    }
  }
});

const ifSystem = computed(() => {
  return props.info.userId == SYSTEM_USER_ID;
});
const emits = defineEmits(["submit", "addVariable"]);
// 变量表格加载列表
const tableLoading = ref(false);
const hideTableLoading = () => {
  tableLoading.value = false;
};
const showTableLoading = () => {
  tableLoading.value = true;
};
// 从文件导入时，改变变量名称时需要改变后的变量映射关系
const colNameChangeMap = ref({});
const changeFieldName = (newFieldName, row) => {
  colNameChangeMap.value[row.importVariable] = newFieldName;
};
const indexMethod = (index) => {
  return index + 1;
};
// 新增变量
const handleClickAddVariable = () => {
  emits("addVariable");
  // props.variableTableList.push(newVariable);
};
// 根据变量类型动态更新数值或时间绑定的值
const handleChangeSelect = (type, row) => {
  if (type == NUMBER) {
    row.decimal = 0;
  }
  if (type == DATE) {
    row.dateFormat = "";
  }
  if (type == TEXT || type == LONG_TEXT) {
    row.language = row.language ?? 0; // 中文
  } else {
    row.language = null;
  }
};
// 判断输入框内容是否为空或空格
let variableValidItem;
const isInputVariableValid = () => {
  for (let item of props.variableTableList) {
    if (Object.values(item).includes("")) {
      variableValidItem = item;
      return false;
    }
  }
  return true;
};
// 保存变量
const libraryId = route.params.textLibraryId;
const handleClickSaveVariable = Debounce(async () => {
  if (isInputVariableValid()) {
    let params = {
      libraryId: libraryId,
      fieldList: props.variableTableList
    };
    let res = await httpPost("/lib/field/saveList", params);
    if (res.code == 0) {
      ElMessage.success("保存成功");
    }
    // getVariableList();
    emits("submit");
  } else {
    ElMessage.warning("请填写第" + variableValidItem.sort + "行的信息");
  }
});
// 删除变量
const handleClickDeleteVariable = (row) => {
  ElMessageBox.confirm(
    "此操作将删除该变量，变量删除后，数据记录中对应变量的数据也将一并清空，是否继续？",
    "提示",
    {
      confirmButtonText: "确定",
      cancelButtonText: "取消",
      type: "warning"
    }
  ).then(async () => {
    let params = {
      libraryId: libraryId,
      fieldNameList: [row.fieldName]
    };
    let res = await httpPost("/lib/field/delete", params);
    if (res.code == 0) {
      // getVariableList();
      emits("submit");
      ElMessage({
        type: "success",
        message: "删除成功"
      });
    }
  });
};
// 上下移动
const handleClickMoveVariableSort = (orderChangeNum, sort) => {
  let params = {
    libraryId: libraryId,
    orderChangeNum: orderChangeNum,
    sort: sort
  };
  httpPost(`/lib/field/update/order`, params).then((res) => {
    if (res.code == 0) {
      // getVariableList();
      emits("submit");
    }
  });
};
const handleClickGoBack = () => {
  // getVariableList();
  // emits("submit");
  router.go(-1);
};
// 多选相关
const variableMultipleList = ref([]);
const handleSelectionChange = (val) => {
  variableMultipleList.value = val;
  VARIABLE_OPTION_LIST.value = [];
};

const exportVariable = () => {
  httpPost(`/lib/field/list/${libraryId}`)
    .then((res) => {
      if (res.code == 0) {
        //表头
        let tHeader = [
          "变量名",
          "变量标签",
          "类型",
          "小数位数",
          "格式",
          "不允许空值",
          "列表显示"
        ];
        //列名
        let filterVal = [];
        for (let key in res.data[0]) {
          filterVal.push(key);
        }
        //数据
        let temp = JSON.parse(JSON.stringify(res.data));
        temp.forEach((v) => {
          delete v.sort;
          switch (v.type) {
            case 1:
              v.type = "文本";
              break;
            case 2:
              v.type = "长文本";
              break;
            case 1001:
              v.type = "文本（英文）";
              break;
            case 1002:
              v.type = "长文本（英文）";
              break;
            case 3:
              v.type = "分类文本";
              break;
            case 4:
              v.type = "数值";
              break;
            case 5:
              v.type = "时间";
              break;
            case 6:
              v.type = "链接";
              break;
          }
          switch (v.dateFormat) {
            case 1:
              v.dateFormat = "yyyy-MM-dd";
              break;
            case 2:
              v.dateFormat = "yyyy-MM-dd hh";
              break;
            case 3:
              v.dateFormat = "yyyy-MM-dd hh:mm";
              break;
            case 4:
              v.dateFormat = "yyyy-MM-dd hh:mm:ss";
              break;
            case 7:
              v.dateFormat = "yyyy";
              break;
            case 8:
              v.dateFormat = "yyyy-MM";
              break;
          }
          switch (v.ifPermitNull) {
            case 1:
              v.ifPermitNull = "是";
              break;
            case 0:
              v.ifPermitNull = "否";
              break;
          }
          switch (v.ifVisualizable) {
            case 1:
              v.ifVisualizable = "是";
              break;
            case 0:
              v.ifVisualizable = "否";
              break;
          }
        });
        export2Excel(tHeader, filterVal, temp, `变量`);
      }
    })
    .catch((err) => {
      ElMessage({
        type: "error",
        message: "系统异常！"
      });
    });
};

// 判断当前页面是否是从文件导入数据记录
const ifVariablePage = computed(() => {
  return props.type == "VARIABLE_TABLE";
});
defineExpose({ colNameChangeMap, hideTableLoading, showTableLoading });
</script>

<style lang="scss" scoped>
@import "@/assets/styles/components/list-table";

.button-group {
  .el-button {
    font-size: 14px;
    i {
      font-size: 20px;
    }
  }
}
.list-item-btn-box {
  display: flex;
  .move-btn-box {
    .move-btn {
      width: 20px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
    }
  }
}
.operation-btn-box {
  margin: 20px 0;
}
.variable-btn-box {
  display: flex;
  justify-content: flex-end;
}
</style>
