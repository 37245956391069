<template>
  <div>
    <PageTitle :title="title"></PageTitle>
    <VariableManageTable
      ref="variableTableRef"
      :variableTableList="variableTableList"
      :info="folderLevelList.at(-1)"
      :type="'VARIABLE_TABLE'"
      @submit="getVariableList()"
      @addVariable="handleClickAddVariable()"
    />
  </div>
</template>

<script setup>
import { ref, defineEmits, onMounted } from "vue";
import { useRoute } from "vue-router";
import VariableManageTable from "./components/VariableManageTable.vue";
import { httpPost } from "@/api/httpService.js";
import Variable from "./components/Variable";
import PageTitle from "@/components/base/PageTitle";
import {
  getFolderLevelListByTextLibraryId,
  getFolderBreadcrumbList
} from "./api/getFolderLevelList";
const route = useRoute();
const title = "变量管理";
// 获取变量列表
const variableTableRef = ref(null);
const libraryId = route.params.textLibraryId;
const variableTableList = ref([]);
const getVariableList = async () => {
  variableTableRef.value.showTableLoading();
  let res = await httpPost(`/lib/field/list/${libraryId}`);
  if (res.code == 0) {
    sort(res.data);
    variableTableList.value = res.data;
    variableTableRef.value.hideTableLoading();
  }
};
// 新增变量
const handleClickAddVariable = () => {
  let newVariable = new Variable({
    sort:
      variableTableList.value.length == 0
        ? 1
        : variableTableList.value.length + 1,
    ifEdit: true
  });
  addVariableToTableList(newVariable);
};
const addVariableToTableList = (newVariable) => {
  variableTableList.value.push(newVariable);
};
// 数据排序
const sort = (data) => {
  for (let i = 0; i < data.length; i++) {
    data[i].sort = i + 1;
  }
};
// 面包屑
const emits = defineEmits(["setBreadcrumbList"]);
const folderLevelList = ref([]);
const textLibraryId = route.params.textLibraryId;
const getFolderLevelList = async () => {
  folderLevelList.value = await getFolderLevelListByTextLibraryId(
    textLibraryId
  );
};
onMounted(async () => {
  await getFolderLevelList();
  let breadcrumblist = getFolderBreadcrumbList(folderLevelList.value);
  breadcrumblist.push(
    {
      title: "数据记录",
      to: {
        name: "DataRecordIndex",
        params: {
          textLibraryId: textLibraryId
        }
      }
    },
    {
      title: title
    }
  );
  emits("setBreadcrumbList", breadcrumblist);
  getVariableList();
});
</script>

<style lang="scss" scoped></style>
