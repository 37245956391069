<template>
  <div class="chart" ref="chartRef"></div>
</template>

<script setup>
import {
  reactive,
  ref,
  defineProps,
  defineEmits,
  nextTick,
  defineExpose
} from "vue";
import { useRouter, useRoute } from "vue-router";
import { ElMessage } from "element-plus";
import { httpPost } from "@/api/httpService.js";
import * as echarts from "echarts";
const props = defineProps({
  chartInfo: {
    type: Object,
    required: true
  },
  type: {
    type: String,
    default: "vertical" //'vertical' or 'horizontal'
  }
});
let chart = reactive({});
let option = reactive({});
const chartRef = ref(null);
const setDefaultOption = () => {
  let series = [];
  let legendData = [];
  let color = [];
  props.chartInfo.data.forEach((item, index) => {
    legendData.push({
      name: props.chartInfo.legendNames[index],
      itemStyle: {
        color: item.itemStyle.color
      }
    });
    color.push(item.itemStyle.color);
    series.push({
      name: props.chartInfo.legendNames[index],
      type: "bar",
      data: [item],
      barWidth: 20
    });
  });

  (option = {
    title: [
      {
        text: props.chartInfo.title,
        left: "center"
      }
    ],
    tooltip: {
      trigger: "item",
      axisPointer: {
        type: "shadow"
      }
    },
    color: color,
    legend: {
      orient: "horizontal",
      data: legendData,
      top: "bottom"
    },
    xAxis: [
      {
        // data: [""],
        name: props.chartInfo.xName,
        nameLocation: "middle",
        nameGap: 30,
        type: props.type == "vertical" ? "category" : "value",
        // data: this.type == "vertical" ? this.chartInfo.colNames : undefined,
        splitLine: {
          show: false
        }
      }
    ],
    yAxis: {
      name: props.chartInfo.yName,
      nameLocation: "end",
      nameTextStyle: {
        marginLeft: 30
      },
      splitLine: {
        show: false
      },
      type: props.type == "horizontal" ? "category" : "value"
      // data: this.type == "horizontal" ? this.chartInfo.colNames : undefined,
    }
  }),
    (option.series = series);
  if (props.type == "vertical") {
    option.grid = {
      top: "50px",
      left: "80px",
      right: "80px",
      bottom: "100px"
    };
  } else if (props.type == "horizontal") {
    option.grid = {
      top: "50px",
      left: "140px",
      right: "50px",
      bottom: "100px"
    };
  }
};
const initChartView = () => {
  // chart = echarts.init(ctx.$el);
  chart = echarts.init(chartRef.value);
  setDefaultOption();
  chart.setOption(option);
  nextTick(() => {
    chart.resize();
  });
};
const setChartView = () => {
  nextTick(() => {
    // chart = echarts.init(ctx.$el);
    // chart = echarts.init(chartRef.value);
    if (chart == null) {
      chart = echarts.init(chartRef.value);
    }

    setDefaultOption();
    chart.setOption(option);
    nextTick(() => {
      chart.resize();
    });
  });
};
const exportChart = (type) => {
  let imgUrl = chart.getDataURL({
    type: type,
    backgroundColor: "#fff",
    pixelRatio: 2
  });
  var title = option.title[0].text;
  var name =
    title == "" || title == null || title == undefined
      ? new Date().getTime()
      : title;
  if (window.navigator.msSaveOrOpenBlob) {
    var bstr = atob(imgUrl.split(",")[1]);
    var n = bstr.length;
    var u8arr = new Blob([u8arr]);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    if (type == "jpeg" || type == "jpg") {
      window.navigator.msSaveOrOpenBlob(u8arr, name + ".jpg");
    } else {
      window.navigator.msSaveOrOpenBlob(u8arr, name + ".png");
    }
  } else {
    const a = document.createElement("a");
    a.href = imgUrl;
    if (type == "jpeg" || type == "jpg") {
      a.setAttribute("download", name + ".jpg");
    } else {
      a.setAttribute("download", name + ".png");
    }
    a.click();
  }
};
defineExpose({
  initChartView,
  setChartView,
  exportChart
});
</script>

<style lang="scss" scoped>
.chart {
  height: 400px;
}
</style>
