<template>
  <div class="chart" ref="chartRef"></div>
</template>

<script setup>
import { reactive, ref, defineProps, defineExpose, nextTick } from "vue";
import * as echarts from "echarts";
const props = defineProps({
  chartInfo: {
    type: Object,
    required: true
  }
});
let chart = reactive({});
let option = reactive({});
const chartRef = ref(null);
setTimeout(() => {}, 4000);
const setDefaultOption = () => {
  let defaultOption = {
    tooltip: {
      //坐标轴触发提示框
      trigger: "axis",
      // 坐标轴指示器
      axisPointer: {
        //十字准星
        type: "cross",
        crossStyle: {
          color: "#999"
        }
      },
      show: props.chartInfo.type == "table" ? false : true
    },
    // 图例组件
    legend: {
      //lineName
      data: [props.chartInfo.barLegendName, props.chartInfo.lineLegendName],
      // right: '50px'
      top: 45,
      show: props.chartInfo.type == "table" ? false : true
    },
    title: {
      text: props.chartInfo.title,
      left: "center",
      top: 15
      // show: false,
    },
    xAxis: [
      {
        name: props.chartInfo.xName,
        // nameLocation:'right',
        type: "value",
        // 指示器
        axisPointer: {
          type: "shadow"
        }
        // show: false,
      }
    ],
    yAxis: [
      {
        type: "value",
        name: props.chartInfo.barYName,
        // interval: this.chartInfo   .interval == undefined ? 50 : this.chartInfo.interval,
        axisLabel: {
          formatter: "{value}",
          showMinLabel: false,
          showMaxLabel: false
        },
        // interval: undefined,
        min: 0
        // show: false,
      },
      {
        type: "value",
        name: props.chartInfo.lineYName,
        // interval: this.chartInfo.interval == undefined ? 50 : this.chartInfo.interval,
        axisLabel: {
          formatter: "{value}",
          showMinLabel: false,
          showMaxLabel: false
        },
        min: 0,
        splitLine: {
          //网格线
          show: false
        }
        // show: false,
      }
    ],
    series: [
      {
        name: props.chartInfo.barLegendName,
        type: "bar",
        data: props.chartInfo.barData,
        itemStyle: {
          color: props.chartInfo.barColor
        }
      },
      {
        name: props.chartInfo.lineLegendName,
        type: "line",
        showSymbol: props.chartInfo.type == "table" ? false : true,
        data: props.chartInfo.lineData,
        yAxisIndex: 1,
        itemStyle: {
          color: props.chartInfo.lineColor
        }
      }
    ],
    grid: {
      top: props.chartInfo.type == "table" ? "0" : "100px",
      left: props.chartInfo.type == "table" ? "0" : "40px",
      right: props.chartInfo.type == "table" ? "0" : "15%",
      bottom: props.chartInfo.type == "table" ? "0" : "50px",
      containLabel: props.chartInfo.type == "table" ? false : true
    }
  };
  option = defaultOption;
};
const initChartView = () => {
  // chart = echarts.init(ctx.$el);
  if (!chart.id) {
    // 为了解决bug There is a chart instance already initialized on the dom.
    chart = echarts.init(chartRef.value);
  }
  // chart = echarts.init(chartRef.value);
  if (!chart) return;
  setDefaultOption();
  chart.setOption(option);
  nextTick(() => {
    chart.resize();
  });
};
const setChartView = () => {
  nextTick(() => {
    // chart = echarts.init(ctx.$el);
    chart = echarts.init(chartRef.value);

    setDefaultOption();
    chart.setOption(option);
    nextTick(() => {
      chart.resize();
    });
  });
};
const exportChart = (type) => {
  let imgUrl = chart.getDataURL({
    type: type,
    backgroundColor: "#fff",
    pixelRatio: 2
  });
  var title = option.title.text;
  var name =
    title == "" || title == null || title == undefined
      ? new Date().getTime()
      : title;

  if (window.navigator.msSaveOrOpenBlob) {
    var bstr = atob(imgUrl.split(",")[1]);
    var n = bstr.length;
    var u8arr = new Blob([u8arr]);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    if (type == "jpeg" || type == "jpg") {
      window.navigator.msSaveOrOpenBlob(u8arr, name + ".jpg");
    } else {
      window.navigator.msSaveOrOpenBlob(u8arr, name + ".png");
    }
  } else {
    const a = document.createElement("a");
    a.href = imgUrl;
    if (type == "jpeg" || type == "jpg") {
      a.setAttribute("download", name + ".jpg");
    } else {
      a.setAttribute("download", name + ".png");
    }
    a.click();
  }
};
defineExpose({
  initChartView,
  setChartView,
  exportChart
});
</script>

<style lang="scss" scoped>
.chart {
  height: 400px;
}
</style>
