<template>
  <div>
    <el-table :data="variableTableList" style="width: 100%">
      <el-table-column prop="importVariable" label="导入字段" />
      <el-table-column label="对应变量">
        <template #default="scope">
          <el-select
            v-model="scope.row.fieldValue"
            placeholder="请选择变量"
            clearable
            @change="(value) => hanbleClickChange(value, scope.row)"
          >
            <el-option
              v-for="item in dataRecordTableLabelList"
              :key="item.fieldName"
              :label="item.fieldLabel"
              :value="item.fieldName"
              :disabled="item.ifDisable"
            />
          </el-select>
        </template>
      </el-table-column>
      <el-table-column label="是否忽略">
        <template #default="scope">
          <el-switch
            v-model="scope.row.ifIgnore"
            :active-value="1"
            :inactive-value="0"
          />
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script setup>
import { defineProps, inject } from "vue";
const props = defineProps({
  variableTableList: Array
});
const dataRecordTableLabelList = inject("dataRecordTableLabelList").value;
// 选择变量后，将该变量状态设为禁用  (选择清空后禁用状态会解除)
const hanbleClickChange = (value, row) => {
  dataRecordTableLabelList.forEach((item) => {
    if (item.fieldName == row.fieldName) {
      item.ifDisable = value ? true : false;
      return;
    }
  });
};
</script>

<style lang="scss" scoped></style>
