<template>
  <div class="tag-selector">
    <el-check-tag
      v-for="(tagItem, tagIndex) in tagList"
      :checked="tagItem.checked"
      :key="tagIndex"
      @change="(status) => handleClickTag(status, tagIndex)"
    >
      {{ tagItem[labelProp] }}
    </el-check-tag>
  </div>
</template>

<script setup>
import { reactive, ref, defineProps, defineEmits, watch } from "vue";
import { httpPost } from "@/api/httpService.js";

const emits = defineEmits(["update:data", "change"]);

const props = defineProps({
  data: {
    type: Array,
    default: () => []
  },
  //tag所显示内容的属性名
  labelProp: {
    type: String,
    default: () => "label"
  }
});
const tagList = ref(null);
watch(
  () => props.data,
  (newVal) => {
    tagList.value = newVal;
  },
  {
    immediate: true
  }
);

const handleClickTag = (status, tagIndex) => {
  console.log("click tag", status, tagIndex);
  tagList.value[tagIndex].checked = status;
  emits("update:data", tagList.value);
  emits("change", status, tagIndex);
  console.log("send", tagList.value);
};
</script>

<style lang="scss" scoped>
.tag-selector {
  $tag-bg-color: #ffffff;
  $tag-font-color: #6c6c6c;
  $tag-border-color: rgba(206, 206, 206, 0.75);
  $tag-checked-bg-color: $theme-color;
  $tag-checked-font-color: #ffffff;
  $tag-checked-border-color: $tag-checked-bg-color;

  .el-check-tag {
    background: $tag-bg-color;
    color: $tag-font-color;
    @include default-border($tag-border-color);
    border-radius: 3px;
    margin-right: 16px;
    margin-bottom: 10px;
  }
  .el-check-tag.is-checked {
    @extend .el-check-tag;
    background: $tag-checked-bg-color;
    color: #ffffff;
    @include default-border($tag-checked-bg-color);
  }
}
</style>
