const labelIconList = {
  1: "icon-wenben",
  2: "icon-wenben",
  1001: "icon-wenben",
  1002: "icon-wenben",
  3: "icon-fenleiwenben",
  4: "icon-shuzhi",
  5: "icon-shijian",
  6: "icon-lianjie"
};
export { labelIconList };
