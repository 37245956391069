<template>
  <el-dialog
    v-model="DLGVisible"
    :title="DLGTitle"
    :before-close="closeDLG"
    width="400"
    class="base-dialog"
  >
    <template #header>
      <BaseDialogTitle :title="DLGTitle"></BaseDialogTitle>
    </template>
    <div v-loading="textLoading">
      <i class="iconfont icon-warning" style="color: rgb(255, 160, 51)" />
      {{ DLGText }}
    </div>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="closeDLG">取消</el-button>
        <el-button
          :disabled="textLoading"
          type="primary"
          @click="handleClickConfirm"
        >
          确定
        </el-button>
      </span>
    </template>
  </el-dialog>
</template>
<script setup>
import { reactive, ref, defineProps, defineEmits, defineExpose } from "vue";
import { httpPost } from "@/api/httpService.js";
import { ElMessage } from "element-plus";
import { Debounce } from "@/utils/utils";
import BaseDialogTitle from "@/components/base/BaseDialogTitle.vue";

const emits = defineEmits(["confirm"]);

const textLibraryId = ref("");

// 弹窗相关
const DLGVisible = ref(false);
const DLGTitle = ref("提示");
const openDLG = (params) => {
  DLGVisible.value = true;
  showTextLoading();
  initDLG(params);
};
const closeDLG = () => {
  DLGVisible.value = false;
};
// 初始化弹窗
const initDLG = async (params) => {
  textLibraryId.value = params.textLibraryId;
  await getDLGText();
  hideTextLoading();
};
// 提示文本
const DLGText = ref("");
const TIP_WITH_ANALYSIS = "有已创建的分析引用该文本库，确认删除吗？";
const TIP_WITHOUT_ANALYSIS = "是否确认删除该文本库？";
const getDLGText = async () => {
  let url = "/lib/getRelatedAnalysisNum";
  let params = {
    id: textLibraryId.value
  };
  let res = await httpPost("/lib/getRelatedAnalysisNum", params);
  if (res.code == 0) {
    DLGText.value = res.data == 0 ? TIP_WITHOUT_ANALYSIS : TIP_WITH_ANALYSIS;
  }
};

// 文本loading
const textLoading = ref(false);
const showTextLoading = () => {
  textLoading.value = true;
};
const hideTextLoading = () => {
  textLoading.value = false;
};

// 点击确认
const handleClickConfirm = Debounce(async () => {
  closeDLG();
  await deleteTextLibrary();
  emits("confirm");
});

// 删除文本库
const deleteTextLibrary = async () => {
  let url = "/view/delete";
  let params = {
    libraryIdList: [textLibraryId.value]
  };
  let res = await httpPost(url, params);
  if (res.code == 0) {
    ElMessage.success(res.message);
  }
};
defineExpose({ openDLG, closeDLG });
</script>
<style lang="scss" scoped></style>
