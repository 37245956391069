<template>
  <el-dialog
    v-model="DLGVisible"
    :title="DLGTitle"
    :before-close="closeDLG"
    width="500px"
    class="base-dialog"
  >
    <template #header>
      <BaseDialogTitle :title="DLGTitle"></BaseDialogTitle>
    </template>
    <el-form ref="formRef" :model="form" :rules="formRules" @submit.prevent>
      <el-form-item prop="name" label="文本库名称">
        <el-input v-model="form.name"></el-input>
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="closeDLG">取消</el-button>
        <el-button
          type="primary"
          :disabled="confirmLoading"
          @click="handleClickSave(formRef)"
        >
          保存
        </el-button>
      </span>
    </template>
  </el-dialog>
</template>
<script setup>
import { reactive, ref, defineProps, defineEmits, defineExpose } from "vue";
import { httpPost } from "@/api/httpService.js";
import { ERROR_CODE_NAME_DUPLICATE } from "@/constant/error_code.js";
import { ElMessage } from "element-plus";
import BaseDialogTitle from "@/components/base/BaseDialogTitle.vue";

const emits = defineEmits(["submit"]);

// 弹窗相关
const DLGVisible = ref(false);
const DLGTitle = ref("新建文本库");
const openDLG = (params) => {
  form.value = { ...form.value, ...params };
  DLGVisible.value = true;
};
const closeDLG = () => {
  DLGVisible.value = false;
  resetForm();
  hideConfirmLoading();
};

//确认loading
const confirmLoading = ref(false);
const showConfirmLoading = () => {
  confirmLoading.value = true;
};
const hideConfirmLoading = () => {
  confirmLoading.value = false;
};

// 点击确认
const handleClickSave = (_formRef) => {
  if (!_formRef) {
    return;
  }
  _formRef.validate(async (valid, fields) => {
    if (valid) {
      showConfirmLoading();
      let res = await submitForm();
      if (res.code == 0) {
        ElMessage.success(res.message);
        emits("submit");
        closeDLG();
      } else if (res.code == ERROR_CODE_NAME_DUPLICATE) {
        ElMessage.warning(res.message);
      }
      hideConfirmLoading();
    }
  });
};
// 表格相关
const form = ref({
  id: "",
  name: "",
  parentFolderId: 0,
  ifChildFolder: 0 //是否为文件夹，1是0否
});
const formRef = ref(null);
const formRules = ref({
  name: [{ required: true, message: "请输入文本库名称", trigger: "blur" }]
});
// 重置表格
const resetForm = () => {
  formRef.value.resetFields();
};
// 提交表单
const submitForm = async () => {
  let url = "/view/save";
  let params = { ...form.value };
  let res = await httpPost(url, params);
  return res;
};

defineExpose({ openDLG, closeDLG });
</script>
<style lang="scss" scoped></style>
