<template>
  <div>
    <PageTitle :title="title" />
    <TextLibraryTable :folderLevelList="folderLevelList" />
  </div>
</template>

<script setup>
import { onMounted, reactive, ref, defineEmits } from "vue";
import TextLibraryTable from "./components/TextLibraryTable.vue";
import PageTitle from "@/components/base/PageTitle.vue";
import { httpPost } from "@/api/httpService";
import { useRoute } from "vue-router";
import {
  getFolderLevelListByFolderId,
  getFolderBreadcrumbList
} from "./api/getFolderLevelList";
const route = useRoute();
const emits = defineEmits(["setBreadcrumbList"]);

const title = "文本库";

const folderId = route.params.folderId;
const folderLevelList = ref([]);
const getFolderLevelList = async () => {
  folderLevelList.value = await getFolderLevelListByFolderId(folderId);
};

onMounted(async () => {
  await getFolderLevelList();
  console.log("get folderlist", folderLevelList.value);

  let breadcrumblist = getFolderBreadcrumbList(folderLevelList.value);
  emits("setBreadcrumbList", breadcrumblist);
  console.log("get breadcrumblist", breadcrumblist);
});
</script>

<style lang="scss" scoped></style>
