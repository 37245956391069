<template>
  <el-dialog
    v-model="DLGVisible"
    :title="DLGTitle"
    :before-close="closeDLG"
    class="base-dialog"
  >
    <template #header>
      <BaseDialogTitle :title="DLGTitle"></BaseDialogTitle>
    </template>
    <div>
      <el-form :model="form" @submit.prevent ref="formRef">
        <el-form-item label="去重变量：" required>
          <TagSelector
            v-model:data="form.variableList"
            label-prop="fieldLabel"
            value-prop="fieldName"
            @change="onChangeTag"
          ></TagSelector>
        </el-form-item>
      </el-form>
    </div>

    <template #footer>
      <span class="dialog-footer">
        <el-button @click="closeDLG">取消</el-button>
        <el-button type="primary" @click="handleClickConfirm">确定</el-button>
      </span>
    </template>
  </el-dialog>
</template>
<script setup>
import {
  reactive,
  ref,
  defineProps,
  defineEmits,
  defineExpose,
  watch
} from "vue";
import { httpPost } from "@/api/httpService.js";
import TagSelector from "@/components/selector/TagSelector.vue";
import { ElMessage } from "element-plus";
import { confirmDeleteBox } from "@/utils/messageBox.js";
import BaseDialogTitle from "@/components/base/BaseDialogTitle.vue";
const emits = defineEmits(["refresh"]);
// 弹窗相关
const DLGVisible = ref(false);
const DLGTitle = ref("去重");
const openDLG = (params) => {
  DLGVisible.value = true;
  libraryId.value = params.libraryId;
  getVariableList();
};
const closeDLG = () => {
  DLGVisible.value = false;
  resetForm();
};
const onChangeTag = (val, index) => {
  console.log("onchange", val, index);
};
const libraryId = ref(null);

// const variableList = ref([
//   { value: 1, label: 1111111 },
//   { value: 1, label: 11111111111 },
//   { value: 1, label: 1111111111 },
//   { value: 1, label: 11111111111 },
//   { value: 1, label: 11111111111 },
//   { value: 1, label: 111111111 },
//   { value: 1, label: 111111111111 },
//   { value: 1, label: 111111111 },
//   { value: 1, label: 1111111111 },
//   { value: 1, label: 1111111111 },
//   { value: 1, label: 11111111111 },
//   { value: 1, label: 111111111 },
//   { value: 1, label: 1111111 },
//   { value: 1, label: 111111111111 }
// ]);
// watch(
//   () => variableList,
//   (newVal) => {
//     console.log("watch", newVal);
//   },
//   {
//     immediate: true,
//     deep: true
//   }
// );
const getVariableList = async () => {
  let res = await httpPost(`/lib/field/list/${libraryId.value}`);
  if (res.code == 0) {
    form.value.variableList = res.data;
  }
};

// 点击确认
const handleClickConfirm = async () => {
  console.log("confirm", form.value.variableList);
  let fieldList = form.value.variableList.filter((item) => item.checked);
  if (fieldList.length == 0) {
    ElMessage.warning("请先选择变量");
    return;
  }
  let params = {
    libraryId: libraryId.value,
    fieldNameList: fieldList.map((item) => item.fieldName)
  };
  let confirmDeduplicateRes = await getConfirmDeduplicateInfo(params);
  if (confirmDeduplicateRes.code == 0) {
    let duplicateNum = confirmDeduplicateRes.data;
    if (duplicateNum) {
      showDepulicateConfirm(confirmDeduplicateRes.data);
    } else {
      ElMessage.warning("没有要去重的数据");
    }
  }
};

const getConfirmDeduplicateInfo = async (params) => {
  let res = await httpPost("/lib/record/update/deduplication/get", params);
  return res;
};
const showDepulicateConfirm = (num) => {
  confirmDeleteBox(`您有${num}条数据需要去重，是否确认去重?`).then(async () => {
    // console.log("111");
    let res = await deduplicateDataRecord();
    if (res.code == 0) {
      ElMessage.success(res.message);
    }
    emits("refresh");
    closeDLG();
  });
};
const deduplicateDataRecord = async () => {
  let res = await httpPost(
    `/lib/record/update/deduplication/delete/${libraryId.value}`
  );
  return res;
};
// 表格相关
const form = ref({
  variableList: []
});
const formRef = ref(null);
const formRules = ref({});
// 重置表格
const resetForm = () => {
  formRef.value.resetFields();
};

defineExpose({ openDLG, closeDLG });
</script>
<style lang="scss" scoped></style>
