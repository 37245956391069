<!-- 文本库-数据操作-一键分析 -->
<template>
  <div class="analysis">
    <div
      class="analysis-item"
      v-for="(
        variablesModelMap, variablesModelMapListIndex
      ) in variablesModelMapList"
      :key="variablesModelMap + variablesModelMapListIndex"
    >
      <el-select
        v-model="variablesModelMap.fieldName"
        @change="(value) => handleChangeVariable(value, variablesModelMap)"
        clearable
        placeholder="请选择变量"
        class="variable-select"
      >
        <el-option
          v-for="variable in variables"
          :key="variable.fieldName"
          :label="
            variable.fieldLabel + '（' + VARIABLE_LIST[variable.type] + '）'
          "
          :value="variable.fieldName"
        />
      </el-select>
      <!-- {{ variablesModelMap }} -->
      <el-select
        v-model="variablesModelMap.oneKeyAnalysisList"
        multiple
        clearable
        class="item-select"
        no-data-text="暂不支持任何分析"
        placeholder="请选择分析方法"
      >
        <el-option
          v-for="mode in modes[variablesModelMap.type]"
          :key="mode"
          :label="ANALYSIS_TEMPALTE_LABEL_MAP[mode]"
          :value="mode"
          :disabled="ifDisabled(variablesModelMap.fieldName, mode)"
        />
      </el-select>
      <i
        class="iconfont tab-icon icon-yuanjiahao"
        @click="addVariablesModelMap"
      ></i>
      <i
        v-if="variablesModelMapListIndex !== 0"
        class="iconfont tab-icon icon-yuanjianhao"
        @click="deleteVariablesModelMap(variablesModelMapListIndex)"
      ></i>
    </div>
  </div>
</template>
<script setup>
import {
  reactive,
  ref,
  defineProps,
  defineEmits,
  onMounted,
  defineExpose
} from "vue";
import { httpPost } from "@/api/httpService.js";
import { useRoute } from "vue-router";
import { VARIABLE_LIST } from "@/constant/variable.js";
import { ANALYSIS_TEMPALTE_LABEL_MAP } from "@/constant/analysis_template.js";
const variables = ref([]);
const modes = ref([]);
const variablesModelMapList = ref([]);
variablesModelMapList.value.push({
  fieldName: "", //变量名
  oneKeyAnalysisList: [] //模型列表
});
// 增加新的一行
const addVariablesModelMap = () => {
  variablesModelMapList.value.push({
    variable: "",
    oneKeyAnalysisList: []
  });
};
// 删除一行
const deleteVariablesModelMap = (index) => {
  variablesModelMapList.value.splice(index, 1);
};
const route = useRoute();
const libraryId = route.params.textLibraryId;
// 初始化
const getOneKeyAnalysisGetMap = async () => {
  let res = await httpPost(`/lib/field/list/${libraryId}`);
  variables.value = res.data;
  let res2 = await httpPost("lib/record/oneKeyAnalysis/getMap");
  modes.value = res2.data;
  console.log("variables", variables);
};
const handleChangeVariable = (value, map) => {
  let variable = variables.value.find((item) => item.fieldName == value);
  map.type = variable.type;
};
// 判断分析方法是否已经被选择
const ifDisabled = (fieldName, mode) => {
  let modeHave = [];
  variablesModelMapList.value.forEach((variablesModelMap) => {
    if (variablesModelMap.fieldName === fieldName) {
      modeHave.push(...variablesModelMap.oneKeyAnalysisList);
    }
  });
  return modeHave.indexOf(mode) !== -1;
};
onMounted(() => {
  getOneKeyAnalysisGetMap();
});
defineExpose({
  variablesModelMapList
});
</script>

<style lang="scss" scoped>
.analysis {
  padding: 0 20px;

  .analysis-item {
    margin-bottom: 20px;
    display: flex;
    align-items: center;

    .tab-icon {
      font-size: 20px;
      margin-left: 10px;
      cursor: pointer;
    }

    .variable-select {
      width: 30%;
    }

    .item-select {
      margin-left: 20px;
      width: 50%;
    }
  }
}
</style>
