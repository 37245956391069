const VARIABLE_OPTION_LIST = [
  {
    value: 1,
    label: "文本"
  },
  {
    value: 2,
    label: "长文本"
  },
  // {
  //   value: 1001,
  //   label: "文本（英文）"
  // },
  // {
  //   value: 1002,
  //   label: "长文本（英文）"
  // },
  {
    value: 3,
    label: "分类文本"
  },
  {
    value: 4,
    label: "数值"
  },
  {
    value: 5,
    label: "时间"
  },
  {
    value: 6,
    label: "链接"
  }
];
const VARIABLE_LIST = {
  1: "文本",
  2: "长文本",
  // 1001: "文本（英文）",
  // 1002: "长文本（英文）",
  3: "文本分类",
  4: "数值",
  5: "时间",
  6: "链接"
};
const TEXT = 1; // 文本
const LONG_TEXT = 2; // 长文本
const TEXT_CLASSIFY = 3; //分类文本
const NUMBER = 4; // 数值
const DATE = 5; // 时间
const LINK = 6; // 链接
const TEXT_ENGLISH = 1001; // 文本（英文）
const LONG_TEXT_ENGLISH = 1002; // 长文本（英文）
const DATAFORMAT_OPTION_LIST = [
  {
    value: 7,
    label: "yyyy"
  },
  {
    value: 8,
    label: "yyyy-MM"
  },
  {
    value: 1,
    label: "yyyy-MM-dd"
  },
  {
    value: 2,
    label: "yyyy-MM-dd hh"
  },
  {
    value: 3,
    label: "yyyy-MM-dd hh:mm"
  },
  {
    value: 4,
    label: "yyyy-MM-dd hh:mm:ss"
  }
];
const NUMBER_OPERATION_RELATION_LIST = [
  {
    value: "=",
    label: "等于"
  },
  {
    value: ">",
    label: "大于"
  },
  {
    value: "<",
    label: "小于"
  },
  {
    value: "<=",
    label: "不大于"
  },
  {
    value: ">=",
    label: "不小于"
  }
];
const TEXT_OPERATION_RELATION_LIST = [
  {
    value: "__contain",
    label: "包含"
  },
  {
    value: "__except",
    label: "不包含"
  },
  {
    value: "=",
    label: "等于"
  }
];
export const LANGUAGE_LIST = [
  {
    value: 0,
    label: "中文"
  },
  {
    value: 2,
    label: "英文"
  }
];
export {
  VARIABLE_OPTION_LIST,
  DATAFORMAT_OPTION_LIST,
  NUMBER_OPERATION_RELATION_LIST,
  TEXT_OPERATION_RELATION_LIST,
  NUMBER,
  DATE,
  TEXT,
  LONG_TEXT,
  TEXT_CLASSIFY,
  LINK,
  TEXT_ENGLISH,
  LONG_TEXT_ENGLISH,
  VARIABLE_LIST
};
