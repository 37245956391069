<template>
  <div class="chart" ref="chartRef"></div>
</template>

<script setup>
import { reactive, ref, defineProps, nextTick, defineExpose } from "vue";
import { useRouter, useRoute } from "vue-router";
import { ElMessage } from "element-plus";
import { httpPost } from "@/api/httpService.js";
import * as echarts from "echarts";
const props = defineProps({
  chartInfo: {
    type: Object,
    required: true
  }
});
let chart = reactive({});
let option = reactive({});
const chartRef = ref(null);
const setDefaultOption = () => {
  let dataList = props.chartInfo.dataList;
  let countList = props.chartInfo.countList;

  option = {
    title: [
      {
        text: props.chartInfo.title,
        left: "center"
      }
    ],
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "cross",
        label: {
          backgroundColor: "#6a7985"
        }
      }
    },
    dataZoom: [
      {
        show: true,
        realtime: true,
        // start: 30,
        // end: 70,
        startValue: props.chartInfo.startValue,
        endValue: props.chartInfo.endValue,
        xAxisIndex: [0, 1]
      },
      {
        type: "inside",
        realtime: true
        // start: 30,
        // end: 70
      }
    ],
    grid: {
      left: "3%",
      right: "4%",
      bottom: "10%",
      containLabel: true
    },
    xAxis: [
      {
        type: "category",
        boundaryGap: false,
        data: dataList
      }
    ],
    yAxis: [
      {
        type: "value"
      }
    ],
    series: [
      {
        name: "文本数量",
        symbol: "none",
        type: "line",
        areaStyle: {
          color: "rgba(51, 198, 247,0.07)"
        },
        data: countList,
        smooth: true,
        color: "rgb(51, 198, 247)"
      }
    ]
  };
};
const initChartView = () => {
  nextTick(() => {
    if (!chart.id) {
      // chart = echarts.init(ctx.$el);
      chart = echarts.init(chartRef.value);
    }
    setDefaultOption();
    chart.setOption(option, true);
    chart.getOption().dataZoom[0];
    nextTick(() => {
      chart.resize();
    });
  });
};
const setChartView = () => {
  nextTick(() => {
    setDefaultOption();
    chart.setOption(option, true);
    chart.getOption().dataZoom[0];
    nextTick(() => {
      chart.resize();
    });
  });
};
const exportResult = () => {
  exportChart();
};
const exportChart = (type) => {
  let imgUrl = chart.getDataURL({
    type: type,
    backgroundColor: "#fff",
    pixelRatio: 2
  });
  var title = option.title[0].text;
  var name =
    title == "" || title == null || title == undefined
      ? new Date().getTime()
      : title;
  if (window.navigator.msSaveOrOpenBlob) {
    var bstr = atob(imgUrl.split(",")[1]);
    var n = bstr.length;
    var u8arr = new Blob([u8arr]);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    if (type == "jpeg" || type == "jpg") {
      window.navigator.msSaveOrOpenBlob(u8arr, name + ".jpg");
    } else {
      window.navigator.msSaveOrOpenBlob(u8arr, name + ".png");
    }
  } else {
    const a = document.createElement("a");
    a.href = imgUrl;
    if (type == "jpeg" || type == "jpg") {
      a.setAttribute("download", name + ".jpg");
    } else {
      a.setAttribute("download", name + ".png");
    }
    a.click();
  }
};
defineExpose({
  initChartView,
  setChartView,
  exportChart
});
</script>

<style lang="scss" scoped>
.chart {
  // width: 800px;
  height: 400px;
}
</style>
