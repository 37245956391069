<template>
  <div class="upload-container">
    <el-upload
      class="upload-file"
      drag
      action=""
      ref="uploadRef"
      :file-list="uploadState.fileList"
      accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
      :auto-upload="false"
    >
      <i class="iconfont icon-daoru upload-icon"></i>
      <div class="upload-text">点击添加文件</div>
      <template #tip>
        <div class="upload-tip">
          只能上传Excel格式文件，文件大小不超过50M，文件首行为标题行且不为空值，详见
          <el-link
            :underline="false"
            type="danger"
            @click="handleClickDownLoadTemplateFile()"
          >
            模板文件
          </el-link>
          。
        </div>
      </template>
    </el-upload>
    <span>
      {{ uploadState.fileName }} 合计 {{ uploadState.recordNum }} 条记录
    </span>
  </div>
  <!-- 数据记录为空时显示 -->
  <VariableManageTable
    v-if="ifDataRecordEmpty"
    ref="variableTableRef"
    :variableTableList="variableTableList"
    :type="'IMPORT_FROM_FILE'"
  />
  <!-- 数据记录不为空时显示 -->
  <DataRecordImportTableHasContent
    v-else
    :variableTableList="variableTableList"
  />
</template>

<script setup>
import { reactive, ref, onMounted, defineExpose, inject } from "vue";
import { useRouter, useRoute } from "vue-router";
import { ElMessage, ElLoading } from "element-plus";
import { httpPost } from "@/api/httpService.js";
import { export2Excel } from "@/utils/excel.js";
import Variable from "./Variable";
import VariableManageTable from "./VariableManageTable.vue";
import DataRecordImportTableHasContent from "./DataRecordImportTableHasContent.vue";
import * as XLSX from "xlsx";
const route = useRoute();
// 判断数据记录是否为空
const ifDataRecordEmpty = inject("ifDataRecordEmpty").value;
// 上传相关
const uploadRef = ref(null);
const uploadState = reactive({
  ws: [], //excel文件内容
  recordNum: 0,
  fileList: [],
  fileName: ""
});
// 下载模板文件
const handleClickDownLoadTemplateFile = () => {
  //表头
  let tHeader = ["标题", "作者", "时间", "来源", "内容"];
  //列名
  let filterVal = ["title", "auth", "time", "source", "content"];

  //数据
  let temp = [
    {
      title: "四小时降至几分钟！防疫物资调配上“云” 战“疫”力大增｜全面上云",
      auth: "王皙皙",
      time: "2020-02-12",
      source: "第一财经",
      content:
        "四小时降至几分钟！防疫物资调配上“云” 战“疫”力大增｜全面上云 战“疫”力拼的是速度和效率。近日，多地防疫物资系统搬到了“云”上，大幅提高抗疫效率，让原本需要四小时才能完成的物资调配汇总时间大幅缩短到几分钟，由此，口罩、防护服、酒精、药品等防疫物资时时在线，一目了然，抗疫火力十足。 此外，各地还出现了政务联络机器人来排查疫情，上海的“一网通办”更将100多项社会服务搬到了云端......政府的云端“战疫”一触即发，未来可期。  "
    }
  ];
  export2Excel(tHeader, filterVal, temp, "示例模板");
};

let loadingService = null;

const openFullScreen = () => {
  loadingService = ElLoading.service({
    lock: true,
    text: "解析中请稍后......",
    background: "rgba(248, 248, 250, 0.6)"
  });
};
const closeFullScreen = () => {
  loadingService.close();
};
// 读取表格
const variableTableList = ref([]);
function readExcel(e) {
  openFullScreen();
  const files = e.target.files;
  if (files.length <= 0) {
    //如果没有文件名
    ElMessage.error("请先选择文件再上传！");
    return false;
  } else if (!/\.(xls|xlsx)$/.test(files[0].name.toLowerCase())) {
    ElMessage.error("上传格式不正确，请上传xls或者xlsx格式");
    return false;
  } else if (files[0].size / 1024 > 1024 * 50) {
    ElMessage.error("上传文件不能大于50M！");
    return false;
  }
  const fileReader = new FileReader();
  fileReader.onload = (ev) => {
    try {
      const data = ev.target.result;
      const workbook = XLSX.read(data, {
        type: "binary"
      });
      const wsname = workbook.SheetNames[0]; //取第一张表
      const ws = XLSX.utils.sheet_to_json(workbook.Sheets[wsname], {
        raw: false
      }); //生成json表格内容
      // console.log("wb", workbook);
      uploadState.ws = ws.map((record) => {
        let processRecord = {};
        for (let key in record) {
          processRecord[key.trim()] = record[key];
        }
        return processRecord;
      });
      // console.log("ws", uploadState.ws);
      uploadState.recordNum = ws.length;
      variableTableList.value = [];
      let index = 0;
      for (let i = 1; i < ws.length; i++) {
        if (Object.keys(ws[i]).length > Object.keys(ws[index]).length) {
          index = i;
        }
      }
      let keys = Object.keys(uploadState.ws[index]);
      let i = 0;
      for (let key in keys) {
        i++;
        let newVariable = new Variable({
          sort: i,
          fieldName: keys[key]?.trim(),
          fieldLabel: keys[key]?.trim(),
          type: 2,
          language: 0
        });
        newVariable.importVariable = keys[key];
        variableTableList.value.push(newVariable);
      }
      // console.log("variabetablelist", variableTableList.value);
      closeFullScreen();
    } catch (e) {
      closeFullScreen();

      return false;
    }
  };
  fileReader.readAsBinaryString(files[0]);
  uploadState.fileName = files[0].name;
  let fileName = {
    name: files[0].name,
    url: ""
  };
  uploadState.fileList.push(fileName);
}
// 批量导入记录
const variableTableRef = ref(null);
const handleClickImportDataRecord = async () => {
  let libraryId = route.params.textLibraryId;
  let params = {
    contentList: JSON.parse(JSON.stringify(uploadState.ws)),
    fieldList: JSON.parse(JSON.stringify(variableTableList.value)),
    libraryId: libraryId
  };
  if (ifDataRecordEmpty) {
    params.colNameChangeMap = variableTableRef.value.colNameChangeMap;
  }
  let res = await httpPost("/lib/record/import/excel", params);
  if (res.code == 0) {
    if (res.data.length == 0) {
      ElMessage.success(res.message);
    } else {
      ElMessage.warning("导入数据有误，将自动为您下载前100条错误数据");
      let filterVal = [];
      for (let k in res.data[0]) {
        filterVal.push(k);
      }
      let headers = filterVal;

      export2Excel(headers, filterVal, res.data, "错误数据前100条");
    }
  } else {
    ElMessage.warning(res.message);
  }
  return res;
};
// 关闭对话框初始化
const handleClickCloseInit = () => {
  variableTableList.value = [];
  uploadState.ws = [];
  uploadState.recordNum = 0;
  uploadState.fileList = [];
  uploadState.fileName = "";
};
onMounted(() => {
  uploadRef.value.$el.addEventListener("change", (e) => {
    //绑定监听表格导入事件
    uploadState.fileList = [];
    readExcel(e);
  });
});
defineExpose({ handleClickImportDataRecord, handleClickCloseInit });
</script>

<style lang="scss" scoped>
.upload-container {
  margin-bottom: 10px;
  .upload-file {
    margin-bottom: 16px;
    :deep(.el-upload-dragger) {
      padding-top: 16px;
      padding-bottom: 20px;
      @include default-border($width: 0);
      background-color: rgba(233, 71, 67, 0.11);
      margin-bottom: 20px;
    }
    :deep(.el-upload-list) {
      width: 150px;
    }
    .upload-icon {
      display: block;
      font-size: 30px;
      color: $theme-color;
      margin-bottom: 12px;
    }
    .upload-tip {
      .el-link {
        margin-top: -2px;
      }
    }
  }
}
</style>
