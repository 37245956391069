class Variable {
  constructor({
    fieldName = "",
    fieldLabel = "",
    type = "",
    decimal = null,
    dateFormat = null,
    ifPermitNull = 1, //1-true 0-false
    ifVisualizable = 1, //1-true 0-false
    ifIgnore = 0,
    sort = 0,
    ifEdit = null,
    fieldValue = null,
    language = null
  }) {
    this.fieldName = fieldName;
    this.fieldLabel = fieldLabel;
    this.type = type;
    this.decimal = decimal;
    this.dateFormat = dateFormat;
    this.ifPermitNull = ifPermitNull;
    this.ifVisualizable = ifVisualizable;
    this.ifIgnore = ifIgnore;
    this.sort = sort;
    this.ifEdit = ifEdit;
    this.fieldValue = fieldValue;
    this.language = language;
  }
}
export default Variable;
