<template>
  <el-drawer
    width="600px"
    v-model="DLGVisible"
    :before-close="closeDLG"
    custom-class="base-drawer"
    class="base-drawer"
  >
    <template #header>
      <BaseDialogTitle :title="DLGTitle" />
    </template>
    <div class="data-record-form">
      <el-form
        ref="formRef"
        :model="dataRecordForm"
        :rules="formRules"
        label-width="130px"
        label-position="top"
        :validate-on-rule-change="false"
      >
        <el-form-item
          v-for="(dataRecordItem, dataRecordIndex) in dataRecordFormLabelList"
          :prop="dataRecordItem.fieldName"
          :key="'formItem' + dataRecordIndex"
        >
          <template #label>
            <i
              :class="[
                'iconfont',
                labelIconList[dataRecordItem.type],
                'label-icon'
              ]"
            ></i>
            {{ dataRecordItem.fieldLabel + "：" }}
          </template>
          <el-input
            v-model.trim="dataRecordForm[dataRecordItem.fieldName]"
            :disabled="dataRecordFormState == 'DETAIL'"
            v-if="
              dataRecordItem.type == TEXT ||
              dataRecordItem.type == TEXT_ENGLISH ||
              dataRecordItem.type == TEXT_CLASSIFY ||
              dataRecordItem.type == LINK
            "
          ></el-input>
          <el-input
            v-model.trim="dataRecordForm[dataRecordItem.fieldName]"
            type="number"
            :disabled="dataRecordFormState == 'DETAIL'"
            v-if="dataRecordItem.type == NUMBER"
          ></el-input>
          <el-input
            v-model.trim="dataRecordForm[dataRecordItem.fieldName]"
            :disabled="dataRecordFormState == 'DETAIL'"
            :rows="5"
            type="textarea"
            v-if="
              dataRecordItem.type == LONG_TEXT ||
              dataRecordItem.type == LONG_TEXT_ENGLISH
            "
          ></el-input>
          <!-- <el-date-picker
            v-model="dataRecordForm[dataRecordItem.fieldName]"
            type="date"
            placeholder="Select date and time"
            :disabled="dataRecordFormState == 'DETAIL'"
            v-if="dataRecordItem.type == DATE"
            :disabled-date="isDisabledDate"
          ></el-date-picker> -->
          <el-date-picker
            v-model="dataRecordForm[dataRecordItem.fieldName]"
            :type="dateMap[dataRecordItem.dateFormat]"
            :disabled="dataRecordFormState == 'DETAIL'"
            :value-format="DATE_VALUE_FORMAT_MAP[dataRecordItem.dateFormat]"
            v-if="dataRecordItem.type == DATE && dateType(dataRecordItem)"
            :disabled-date="isDisabledDate"
          />
          <el-date-picker
            v-model="dataRecordForm[dataRecordItem.fieldName]"
            type="datetime"
            :format="dateMap[dataRecordItem.dateFormat]"
            :value-format="dateMap[dataRecordItem.dateFormat]"
            :disabled="dataRecordFormState == 'DETAIL'"
            :disabled-date="isDisabledDate"
            :disabled-minutes="
              (hour) => disabledMinutes(dataRecordItem.dateFormat)
            "
            v-if="dataRecordItem.type == DATE && !dateType(dataRecordItem)"
          />
        </el-form-item>
      </el-form>
    </div>
    <template #footer>
      <el-button v-show="dataRecordFormState != 'DETAIL'" @click="closeDLG">
        取消
      </el-button>
      <el-button
        v-show="dataRecordFormState != 'DETAIL'"
        type="primary"
        @click="handleClickSave"
        :disabled="ifDisable"
      >
        保存
      </el-button>
    </template>
  </el-drawer>
</template>
<script setup>
import {
  ref,
  defineProps,
  defineEmits,
  defineExpose,
  onMounted,
  computed
} from "vue";
import { httpPost } from "@/api/httpService.js";
import { useRoute } from "vue-router";
import { ElMessage } from "element-plus";
import {
  TEXT,
  LONG_TEXT,
  TEXT_CLASSIFY,
  NUMBER,
  DATE,
  LINK,
  TEXT_ENGLISH,
  LONG_TEXT_ENGLISH
} from "@/constant/variable.js";
import { labelIconList } from "@/constant/data_record.js";
import { Debounce } from "@/utils/utils";
import BaseDialogTitle from "@/components/base/BaseDialogTitle.vue";
import { getFolderLevelListByTextLibraryId } from "../api/getFolderLevelList";
const emits = defineEmits(["submit"]);
const route = useRoute();
const props = defineProps({
  dataRecordFormLabelList: Array
});
// 自定义数字类型校验（根据用户输入的小数位的值进行校验）
const decimal = ref("");
const validateDecimal = (rule, value, callback) => {
  if (value.lastIndexOf(".") != -1) {
    if (decimal.value == 0) {
      callback(new Error("该变量应为整数"));
    } else {
      if (
        value.slice(value.indexOf(".") + 1, value.length).length > decimal.value
      ) {
        callback(new Error("该变量应有" + decimal.value + "位小数"));
      } else {
        callback();
      }
    }
  } else {
    callback();
  }
};
// 为不允许空值的变量添加校验规则
const setDataRecordFormRules = async () => {
  props.dataRecordFormLabelList.forEach((val) => {
    dataRecordForm[val.fieldName] = null;
    if (val.ifPermitNull == 1) {
      if (val.type == 5) {
        formRules.value[val.fieldName] = [
          {
            required: true,
            message: "请选择日期",
            trigger: "change"
          }
        ];
      } else if (val.type == 4) {
        decimal.value = val.decimal;
        formRules.value[val.fieldName] = [
          {
            required: true,
            message: "该变量不允许空值",
            trigger: "blur"
          },
          {
            validator: validateDecimal,
            trigger: "blur"
          }
        ];
      } else {
        formRules.value[val.fieldName] = [
          {
            required: true,
            message: "该变量不允许空值",
            trigger: "blur"
          }
        ];
      }
    }
  });
};
// 弹窗相关
const DLGVisible = ref(false);
const DLGTitle = ref("新建记录");
const textLibraryName = ref("");
const ifDisable = ref(false);
const openDLG = (params, type) => {
  setDataRecordFormRules();
  DLGVisible.value = true;
  dataRecordFormState.value = "CREATE";
  if (params) {
    dataRecordFormState.value = type;
    dataRecordForm.value = JSON.parse(JSON.stringify(params));
    if (type == "EDIT") {
      DLGTitle.value = "编辑记录";
    }
    if (type == "DETAIL") {
      DLGTitle.value = "记录详情";
    }
  }
  if (dataRecordFormState.value == "CREATE") {
    dataRecordForm.value = {};
  }
};
const closeDLG = () => {
  DLGVisible.value = false;
  resetForm();
};
// 点击确认
const handleClickSave = Debounce(() => {
  formRef.value.validate(async (valid) => {
    if (valid) {
      let params = {
        libraryId: libraryId,
        content: dataRecordForm.value,
        id: dataRecordForm.value.id
      };
      let res = await httpPost(`/lib/record/save`, params);
      if (res.code == 0) {
        if (res.data > 0) {
          if (dataRecordFormState.value == "EDIT") {
            ElMessage.success("修改成功");
          } else {
            ElMessage.success("添加成功");
          }
          emits("submit");
          closeDLG();
        } else {
          ElMessage.warning(res.message);
        }
      } else {
        ElMessage.warning(res.message);
      }
    }
  });
});
// 表格相关
const formRef = ref(null);
const formRules = ref({});
const dataRecordForm = ref({});
const dataRecordFormState = ref("CREATE");
const libraryId = route.params.textLibraryId;
// 日期字典
const dateMap = {
  7: "year",
  8: "month",
  1: "date",
  2: "YYYY-MM-DD hh",
  3: "YYYY-MM-DD hh:mm",
  4: "YYYY-MM-DD hh:mm:ss"
};
const DATE_VALUE_FORMAT_MAP = {
  7: "YYYY",
  8: "YYYY-MM",
  1: "YYYY-MM-DD"
};
// 重置表格
const resetForm = () => {
  formRef.value.resetFields();
};
// 判断是否禁用
const isDisabledDate = (time) => {
  var start = new Date("1/1/1949");
  var end = new Date("12/31/2050");
  return time.getTime() > Date.parse(end) || time.getTime() < Date.parse(start);
};
const makeRange = (start, end) => {
  const result = [];
  for (let i = start; i <= end; i++) {
    result.push(i);
  }
  return result;
};
const disabledMinutes = (dateFormat) => {
  if (dateFormat == 2) return makeRange(0, 59);
};
// 获取文本库名称
const folderLevelList = ref([]);
const getFolderLevelList = async () => {
  folderLevelList.value = await getFolderLevelListByTextLibraryId(libraryId);
  textLibraryName.value =
    folderLevelList.value[folderLevelList.value.length - 1].name;
};
// 判断日期格式
const dateType = computed(() => (item) => {
  return item.dateFormat == 7 || item.dateFormat == 8 || item.dateFormat == 1;
});
onMounted(() => {
  getFolderLevelList();
});
defineExpose({ openDLG, closeDLG });
</script>
<style lang="scss" scoped>
:deep(.el-dialog__header) {
  text-align: center;
}
:deep(.el-form-item) {
  align-items: center;
}
:deep(.el-date-editor.el-input, .el-date-editor.el-input__wrapper) {
  width: 100%;
}
.label-icon {
  margin-right: 5px;
}
.data-record-form {
  text-align: center;
  padding: 10px;
  width: 90%;
}
</style>
