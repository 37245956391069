<template>
  <el-dialog
    v-model="DLGVisible"
    :before-close="closeDLG"
    :width="dialogWidth"
    class="base-dialog"
  >
    <template #header>
      <BaseDialogTitle :title="DLGTitle" item-name="" />
    </template>
    <el-tabs class="tab-box">
      <el-tab-pane v-for="tabItem in tabList" :key="tabItem.icon">
        <template #label>
          <span class="tab-label">
            <i :class="['iconfont', 'tab-icon', tabItem.icon]"></i>
            <span>
              {{ tabItem.label }}
            </span>
          </span>
        </template>
        <component :is="tabItem.componentId" ref="componentRef"></component>
      </el-tab-pane>
    </el-tabs>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="closeDLG">取消</el-button>
        <el-button type="primary" @click="handleClickConfirm">确定</el-button>
      </span>
    </template>
  </el-dialog>
</template>
<script setup>
import {
  ref,
  defineEmits,
  defineExpose,
  shallowRef,
  defineProps,
  computed
} from "vue";
import { ElLoading } from "element-plus";
import { httpPost } from "@/api/httpService.js";
import { Calendar } from "@element-plus/icons-vue";
import DataRecordImportFromFile from "./DataRecordImportFromFile.vue";
import ImportFromRingSpider from "./ImportFromRingSpider.vue";
import ImportFromRingData from "./ImportFromRingData.vue";
import BaseDialogTitle from "@/components/base/BaseDialogTitle.vue";
const emits = defineEmits(["getDataRecordTabelLabelList", "getDataRecordList"]);
const componentRef = ref(null); //中心内容模块ref
const props = defineProps({
  ifDataRecordEmpty: Boolean
});
// 弹窗相关
const DLGVisible = ref(false);
const DLGTitle = ref("批量导入");
const tabList = ref([
  {
    icon: "icon-wenbenku-congwenjiandaoru",
    label: "从文件导入",
    key: "",
    componentId: shallowRef(DataRecordImportFromFile)
  },
  // {
  //   icon: "icon-wenbenku-congyuncaijidaoru",
  //   label: "从云采集项目导入",
  //   key: "ImportFromRingSpider",
  //   componentId: shallowRef(ImportFromRingSpider)
  // },
  {
    icon: "icon-congruiyanshujupingtaidaoru",
    label: "从锐研数据平台导入",
    key: "ImportFromRingData",
    componentId: shallowRef(ImportFromRingData)
  }
]); //中心内容模块列表
const openDLG = (params) => {
  DLGVisible.value = true;
};
const closeDLG = () => {
  DLGVisible.value = false;
  handleClickCloseImportFromFile();
};
// 从文件导入
const handleClickCloseImportFromFile = () => {
  emits("getDataRecordTabelLabelList");
  emits("getDataRecordList");
  componentRef.value[0].handleClickCloseInit();
};
// 切换标签相关
const componentId = shallowRef(null); //中心内容模块Id

let loadingService = null;

const openFullScreen = () => {
  loadingService = ElLoading.service({
    lock: true,
    text: "导入中请稍后......",
    background: "rgba(248, 248, 250, 0.6)"
  });
};
const closeFullScreen = () => {
  loadingService.close();
};

// 点击确认
const handleClickConfirm = async () => {
  openFullScreen();
  await componentRef.value[0].handleClickImportDataRecord(); // 调用从文件导入页面中的handleClickImportDataRecord方法
  closeFullScreen();
  closeDLG();
};
// 控制dialog宽度
const dialogWidth = computed(() => {
  return props.ifDataRecordEmpty ? "1160px" : "840px";
});

defineExpose({ openDLG, closeDLG });
</script>
<style lang="scss" scoped>
.tab-box {
  & > .el-tabs__content {
    padding: 32px;
    color: #6b778c;
    font-size: 32px;
    font-weight: 600;
  }
  .tab-label {
    .tab-icon {
      vertical-align: middle;
      margin-right: 5px;
      font-size: 20px;
    }
    span {
      vertical-align: middle;
      margin-left: 4px;
    }
  }
}
:deep(.el-tabs__nav-wrap::after) {
  display: none;
}
:deep(.el-tabs__active-bar) {
  display: none;
}
</style>
