<template>
  <div class="chart" ref="chartRef"></div>
</template>

<script setup>
import { reactive, ref, defineProps, defineExpose, nextTick } from "vue";
import * as dataTool from "echarts/extension/dataTool";
import * as echarts from "echarts";
const props = defineProps({
  chartInfo: {
    type: Object,
    required: true
  }
});
let chart = reactive({});
let option = reactive({});
const chartRef = ref(null);
const setDefaultOption = () => {
  let data = echarts.dataTool.prepareBoxplotData(props.chartInfo.dataSource[0]);

  option = {
    title: [
      {
        text: props.chartInfo.title,
        left: "center"
      }
    ],
    tooltip: {
      trigger: "item",
      axisPointer: {
        type: "shadow"
      }
    },
    grid: {
      top: "40px",
      left: "30px",
      right: "40px",
      bottom: "30px",
      containLabel: true
    },
    xAxis: {
      name: props.chartInfo.xName,
      nameLocation: "middle",
      type: "category",
      data: props.chartInfo.labels[0],
      boundaryGap: true,
      axisLabel: {
        formatter: "{value}"
      },
      nameGap: 30,
      splitArea: {
        show: false
      }
    },
    yAxis: {
      type: "value",
      name: props.chartInfo.yName,
      // axisLabel: {
      //   showMinLabel: false,

      // },
      scale: true
    },
    series: [
      {
        name: "boxplot",
        type: "boxplot",
        data: data.boxData,
        itemStyle: {
          color: props.chartInfo.boxplotColor,
          borderColor: props.chartInfo.borderColor
        }
      },
      {
        name: "outlier",
        type: "scatter",
        data: data.outliers,
        itemStyle: {
          color: props.chartInfo.outliersColor
        }
      }
    ]
  };
};
const initChartView = () => {
  // chart = echarts.init(ctx.$el);
  chart = echarts.init(chartRef.value);

  setDefaultOption();
  chart.setOption(option);
  nextTick(() => {
    chart.resize();
  });
};
const setChartView = () => {
  nextTick(() => {
    // chart = echarts.init(ctx.$el);
    chart = echarts.init(chartRef.value);

    setDefaultOption();
    chart.setOption(option);
    nextTick(() => {
      chart.resize();
    });
  });
};
const exportChart = (type) => {
  let imgUrl = chart.getDataURL({
    type: type,
    backgroundColor: "#fff",
    pixelRatio: 2
  });
  var title = option.title[0].text;
  var name =
    title == "" || title == null || title == undefined
      ? new Date().getTime()
      : title;
  if (window.navigator.msSaveOrOpenBlob) {
    var bstr = atob(imgUrl.split(",")[1]);
    var n = bstr.length;
    var u8arr = new Blob([u8arr]);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    if (type == "jpeg" || type == "jpg") {
      window.navigator.msSaveOrOpenBlob(u8arr, name + ".jpg");
    } else {
      window.navigator.msSaveOrOpenBlob(u8arr, name + ".png");
    }
  } else {
    const a = document.createElement("a");
    a.href = imgUrl;
    if (type == "jpeg" || type == "jpg") {
      a.setAttribute("download", name + ".jpg");
    } else {
      a.setAttribute("download", name + ".png");
    }
    a.click();
  }
};
defineExpose({
  initChartView,
  setChartView,
  exportChart
});
</script>

<style lang="scss" scoped>
.chart {
  height: 450px;
}
</style>
