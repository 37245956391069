<template>
  <div class="table-wrapper list-table">
    <el-table :data="LogList" header-cell-class-name="list-table-header">
      <el-table-column
        type="index"
        label="序号"
        width="120px"
      ></el-table-column>
      <el-table-column prop="message" label="日志信息"></el-table-column>
      <el-table-column prop="createTime" label="操作时间"></el-table-column>
      <el-table-column prop="status" label="状态" width="140">
        <template #default="scope">
          <el-button type="success" round v-if="scope.row.status == 1" disabled>
            成功
          </el-button>
          <el-button type="danger" round v-if="scope.row.status == 0" disabled>
            失败
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="pagination-box">
      <el-pagination
        v-model:current-page="paginationState.pageNum"
        v-model:page-size="paginationState.pageSize"
        small
        background
        layout="total, prev, pager, next, sizes"
        :total="paginationState.total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :page-sizes="paginationState.pageSizeList"
      />
    </div>
  </div>
</template>
<script setup>
import { reactive, ref, onMounted } from "vue";
import { useRoute } from "vue-router";
import { httpPost } from "@/api/httpService.js";
const route = useRoute();
const libraryId = route.params.textLibraryId;

// 日志列表数据
const LogList = ref([
  // {
  //   message: "这是一段日志信息",
  //   createTime: "2022-09-01 13:00:00",
  //   status: 1
  // },
  // {
  //   message: "这是一段日志信息",
  //   createTime: "2022-09-01 13:00:00",
  //   status: 0
  // }
]); // 列表数据
//获取当前页面信息（列表数据、文件夹路径）
const getLogList = async () => {
  showTableLoading();
  let url = "/lib/record/log/list/page";
  let params = {
    libraryId: libraryId,
    pageNum: paginationState.pageNum,
    pageSize: paginationState.pageSize
  };
  let res = await httpPost(url, params);
  hideTableLoading();
  if (res.code == 0) {
    console.log(res.data);
    LogList.value = res.data.data;
  }
};
//分页
const paginationState = reactive({
  pageNum: 1,
  pageSize: 10,
  pageSizeList: [10, 20, 50],
  total: 0
});
const handleSizeChange = (val) => {
  paginationState.pageSize = val;
  getLogList();
};
// 切换当前页码
const handleCurrentChange = (val) => {
  paginationState.pageNum = val;
  getLogList();
};
//日志列表加载
const tableLoading = ref(false);
const showTableLoading = () => {
  tableLoading.value = true;
};
const hideTableLoading = () => {
  tableLoading.value = false;
};
onMounted(() => {
  getLogList();
});
</script>
<style lang="scss" scoped>
@import "@/assets/styles/components/list-table";
.table-wrapper {
  .el-button--success.is-disabled,
  .el-button--success.is-disabled:hover,
  .el-button--success.is-disabled:focus {
    color: white;
    background-color: $success-color;
    cursor: default;
  }
  .el-button--danger.is-disabled,
  .el-button--danger.is-disabled:hover,
  .el-button--danger.is-disabled:focus {
    color: white;
    background-color: #f56c6c;
    cursor: default;
  }
}
</style>
