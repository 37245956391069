<template>
  <div id="data-record-table-wrapper" class="list-table">
    <div class="search-bar">
      <DataRecordSearchBar
        ref="dataRecordSearchBarRef"
        :dataRecordFormLabelList="dataRecordTableLabelList"
        @submit="getDataRecordList"
      />
    </div>
    <div class="option-btn-box">
      <el-dropdown @command="handleBatchCommand" v-if="!ifSystem">
        <el-button class="option-dropper-btn">
          批量操作
          <el-icon class="el-icon--right"><arrow-down /></el-icon>
        </el-button>
        <template #dropdown>
          <el-dropdown-menu>
            <el-dropdown-item command="importRecord">导入记录</el-dropdown-item>
            <el-dropdown-item command="exportRecord">导出记录</el-dropdown-item>
            <el-dropdown-item command="batchDelete">删除记录</el-dropdown-item>
            <el-dropdown-item command="clearRecord">清空记录</el-dropdown-item>
          </el-dropdown-menu>
        </template>
      </el-dropdown>
      <el-dropdown @command="handleDataCommand" v-if="!ifSystem">
        <el-button class="option-dropper-btn">
          数据操作
          <el-icon class="el-icon--right"><arrow-down /></el-icon>
        </el-button>
        <template #dropdown>
          <!-- <el-dropdown-menu>
            <el-dropdown-item
              v-for="dataItem in dataOptionList"
              :key="dataItem.value"
              :command="dataItem.value"
            >
              {{ dataItem.label }}
            </el-dropdown-item>
          </el-dropdown-menu> -->
          <!-- <el-dropdown-item command="analysis">一键分析</el-dropdown-item> -->
          <el-dropdown-item command="quickStatistic">快速统计</el-dropdown-item>
          <el-dropdown-item command="log">查看日志</el-dropdown-item>
          <el-dropdown-item command="deduplicate">去重</el-dropdown-item>
          <el-dropdown-item command="replace">替换</el-dropdown-item>
        </template>
      </el-dropdown>
      <el-button @click="handleManageVariable">变量管理</el-button>
      <el-button
        type="primary"
        @click="handleClickCreateDataRecord"
        v-if="!ifSystem"
      >
        新建记录
      </el-button>
    </div>
    <div class="table-container" v-loading="tableLoading">
      <div v-if="!ifShowDataRecordList">
        <div>
          <el-table
            :data="dataRecordTableContent"
            style="width: 100%"
            @selection-change="handleSelectionChange"
            header-cell-class-name="list-table-header"
          >
            <el-table-column type="selection" width="55" />
            <el-table-column
              v-for="item in VarableVisualizableList"
              :key="item.fieldName"
              :prop="item.value"
              :label="item.name"
              show-overflow-tooltip
              align="left"
            ></el-table-column>
            <el-table-column
              label="操作"
              :align="optionColumnAlgin"
              fixed="right"
              :width="optionColumnWidth"
            >
              <template #default="scope">
                <div class="list-item-btn">
                  <el-button
                    link
                    type="primary"
                    v-if="!ifSystem"
                    @click="handleDeleteDataRecord(scope.row)"
                  >
                    删除
                  </el-button>
                  <el-button
                    link
                    type="primary"
                    v-if="!ifSystem"
                    @click="handleClickEditDataRecord(scope.row)"
                  >
                    编辑
                  </el-button>
                  <el-button
                    link
                    type="primary"
                    @click="handleClickGetDataRecordDetail(scope.row)"
                  >
                    详情
                  </el-button>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="pagination-box">
          <el-pagination
            v-model:current-page="paginationState.pageNum"
            v-model:page-size="paginationState.pageSize"
            small
            background
            layout="total, prev, pager, next, sizes"
            :total="paginationState.total"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :page-sizes="paginationState.pageSizeList"
          />
        </div>
      </div>
      <div v-else>
        <EmptyStatus content="您可以批量导入数据记录或者先完成变量配置" />
      </div>
    </div>
    <DataRecordDrawerManage
      v-if="dataRecordTableLabelList.length != 0"
      :dataRecordFormLabelList="dataRecordTableLabelList"
      ref="createDataRecordDLGRef"
      @submit="getDataRecordList"
    />
    <DataRecordDialogDeduplicate
      ref="dataRecordDialogDeduplicateRef"
      @refresh="getDataRecordList()"
    />
    <DataRecordDialogImport
      ref="importDataRecordDLGRef"
      :ifDataRecordEmpty="ifDataRecordEmpty"
      @getDataRecordTabelLabelList="getDataRecordTabelLabelList()"
      @getDataRecordList="getDataRecordList()"
    />
    <DataRecordDialogTableAnalysis ref="analysisDataRecordTableDialogRef" />
    <DataRecordDialogReplace
      @refresh="getDataRecordList"
      ref="replaceDataRecordDialogRef"
    />
  </div>
</template>

<script setup>
import {
  reactive,
  ref,
  defineExpose,
  onMounted,
  computed,
  provide,
  defineProps
} from "vue";
import { useRouter, useRoute } from "vue-router";
import { ElMessage, ElMessageBox } from "element-plus";
import { httpPost, baseUrl } from "@/api/httpService.js";
import DataRecordSearchBar from "./DataRecordSearchBar.vue";
import DataRecordDrawerManage from "./DataRecordDrawerManage.vue";
import DataRecordDialogTableAnalysis from "./DataRecordDialogTableAnalysis.vue";
import DataRecordDialogReplace from "./DataRecordDialogReplace.vue";
import { ArrowDown } from "@element-plus/icons-vue";
import DataRecordDialogImport from "./DataRecordDialogImport.vue";
import DataRecordDialogDeduplicate from "./DataRecordDialogDeduplicate.vue";
import EmptyStatus from "@/components/base/EmptyStatus.vue";
import VueCookies from "vue-cookies";
import { SYSTEM_USER_ID } from "@/constant/system";

const route = useRoute();
const router = useRouter();

const props = defineProps({
  // 文本库信息
  info: {
    type: Object,
    default: () => {
      return {};
    }
  }
});

const ifSystem = computed(() => {
  return props.info.userId == SYSTEM_USER_ID;
});
const dataRecordSearchBarRef = ref(null);
// 数据操作信息列表
// const dataOptionList = ref([
//   {
//     value: "analysis",
//     label: "一键分析"
//   },
//   {
//     value: "quickStatistic",
//     label: "快速统计"
//   },

//数据操作
const handleDataCommand = (command) => {
  switch (command) {
    case "analysis":
      handleClickAnalysisDataCommand();
      break;
    case "quickStatistic":
      handleClickToQuickStatistics();
      break;
    case "log":
      goToLogPage();
      break;
    case "deduplicate":
      handleClickDeduplicate();
      break;
    case "replace":
      handleClickReplace();
      break;
  }
};
const dataRecordDialogDeduplicateRef = ref(null);
const handleClickDeduplicate = () => {
  dataRecordDialogDeduplicateRef.value.openDLG({ libraryId: libraryId });
};
const replaceDataRecordDialogRef = ref(null);
// 打开去重弹窗
const handleClickReplace = () => {
  replaceDataRecordDialogRef.value.openDLG({ libraryId: libraryId });
};
const goToLogPage = () => {
  router.push({
    name: "Log",
    params: {
      textLibraryId: libraryId
    }
  });
};
// 数据记录加载效果
const tableLoading = ref(false);
const showTableLoading = () => {
  tableLoading.value = true;
};
const hideTableLoading = () => {
  tableLoading.value = false;
};
// 获取文本库对应变量
const libraryId = route.params.textLibraryId;
const dataRecordTableLabelList = ref([]);
provide("dataRecordTableLabelList", dataRecordTableLabelList);
const getDataRecordTabelLabelList = async () => {
  let res = await httpPost(`/lib/field/list/${libraryId}`);
  if (res.code == 0) {
    dataRecordTableLabelList.value = res.data;
  }
};
// 分页信息
const paginationState = reactive({
  pageNum: 1,
  pageSize: 10,
  pageSizeList: [10, 20, 30, 40],
  total: 20
});
// 获取文本库数据记录列表
const dataRecordList = ref([]);
const getDataRecordList = async (condition) => {
  showTableLoading();
  let params = {
    libraryId: libraryId,
    condition: condition,
    pageNum: paginationState.pageNum,
    pageSize: paginationState.pageSize
  };
  let res = await httpPost("/lib/record/list/page", params);
  if (res.code == 0) {
    dataRecordList.value = res.data.recordDTOList;
    paginationState.pageNum = res.data.pageNum;
    paginationState.pageSize = res.data.pageSize;
    paginationState.total = res.data.total;
  }
  hideTableLoading();
};
// 删除一条数据记录
const handleDeleteDataRecord = (row) => {
  ElMessageBox.confirm("此操作将删除该数据记录，是否继续？", "Warning", {
    confirmButtonText: "确定",
    cancelButtonText: "取消",
    type: "warning"
  }).then(async () => {
    let params = {
      libraryId: libraryId,
      recordIdList: [row.id]
    };
    let res = await httpPost("/lib/record/delete", params);
    if (res.code == 0) {
      getDataRecordList();
      ElMessage({
        type: "success",
        message: "删除成功"
      });
    }
  });
};
// 编辑一条数据记录
const handleClickEditDataRecord = (row) => {
  createDataRecordDLGRef.value.openDLG(row, "EDIT");
};
// 查看一条数据记录详情
const handleClickGetDataRecordDetail = (row) => {
  createDataRecordDLGRef.value.openDLG(row, "DETAIL");
};
// 批量操作
const handleBatchCommand = (command) => {
  switch (command) {
    case "importRecord":
      handleClickImportDataRecord();
      break;
    case "exportRecord":
      handleClickExportDataRecord();
      break;
    case "batchDelete":
      handleClickBatchDeleteDataRecord(false);
      break;
    case "clearRecord":
      handleClickBatchDeleteDataRecord(true);
      break;
  }
};
// 导入记录
const importDataRecordDLGRef = ref(null);
const handleClickImportDataRecord = () => {
  importDataRecordDLGRef.value.openDLG();
};
const handleClickExportDataRecord = () => {
  let conditionList = dataRecordSearchBarRef.value.conditionState.conditionList;
  let condition = "";
  if (conditionList) {
    //判断筛选条件是否填写完整
    let flag = conditionList.some((v, i) => {
      return (
        v.variableValue == "" ||
        v.variableValue.type ||
        v.relationValue == "" ||
        v.keyWords.trim() == "" ||
        (v.operationValue == "" && i != 0)
      );
    });
    if (flag) {
      //筛选条件不完整
      let con = conditionList[0];
      if (
        con.variableValue == "" &&
        !con.variableValue.type &&
        con.relationValue == "" &&
        con.keyWords.trim() == ""
      ) {
        condition = null;
      } else {
        ElMessage.warning("请将筛选条件填写完整！");
        return;
      }
    } else {
      condition = encodeURI(
        JSON.stringify(dataRecordSearchBarRef.value.exchangeCondition())
      );
    }
  }

  let url =
    baseUrl +
    "lib/record/export/excel?" +
    "libraryId=" +
    libraryId +
    "&access_token=" +
    VueCookies.get("tokenWeb");
  if (condition) {
    url = url + "&condition=" + condition;
  }
  window.location.href = url;
};
// 快速统计
const handleClickToQuickStatistics = () => {
  router.push({
    name: "QuickStatisticsIndex",
    params: libraryId
  });
};
// 批量删除或清空数据记录
const handleClickBatchDeleteDataRecord = (ifAll) => {
  let message = "";
  if (ifAll) {
    message = "清空";
    dataRecordTableContent.value = [];
  } else {
    message = "删除选中的";
    if (dataRecordMultiple.value.length == 0) {
      ElMessage.warning("请选择您要删除的数据记录");
      return;
    }
  }
  ElMessageBox.confirm("此操作将" + message + "数据记录，是否继续？", "提示", {
    confirmButtonText: "确定",
    cancelButtonText: "取消",
    type: "warning"
  }).then(async () => {
    let params = {
      libraryId: libraryId,
      recordIdList: dataRecordMultiple.value
    };
    let res = await httpPost("/lib/record/delete", params);
    if (res.code == 0) {
      getDataRecordList();
      ElMessage({
        type: "success",
        message: "删除成功"
      });
    }
  });
};

// 打开一键分析弹窗
const analysisDataRecordTableDialogRef = ref(null);
const handleClickAnalysisDataCommand = () => {
  analysisDataRecordTableDialogRef.value.openDLG();
};
// 改变页面容纳数据量
const handleSizeChange = (val) => {
  paginationState.pageSize = val;
  getDataRecordList();
};
// 切换当前页码
const handleCurrentChange = (val) => {
  paginationState.pageNum = val;
  getDataRecordList();
};
// 动态更新多选列表
const dataRecordMultiple = ref([]);
const handleSelectionChange = (val) => {
  dataRecordMultiple.value = [];
  val.forEach((item) => {
    dataRecordMultiple.value.push(item.id);
  });
};
// 变量管理
const handleManageVariable = () => {
  router.push({
    name: "VariableManageIndex",
    params: libraryId
  });
};
// 新建记录
const createDataRecordDLGRef = ref(null);
const handleClickCreateDataRecord = () => {
  if (ifShowDataRecordList.value) {
    ElMessage.warning("暂无变量信息，请先配置变量");
  } else {
    createDataRecordDLGRef.value.openDLG(null, "CREATE");
  }
};
// 数据记录表头
const dataRecordTabelTitle = computed(() => {
  return dataRecordTableLabelList.value.map((v) => {
    return {
      name: v.fieldLabel,
      value: v.fieldName,
      ifVisualizable: v.ifVisualizable
    };
  });
});
// 数据记录表格内容
const dataRecordTableContent = computed(() => {
  let tableColumns = [];
  dataRecordList.value.forEach((v) => {
    v.content.id = v.id;
    tableColumns.push(v.content);
  });
  return tableColumns;
});
// 显示可见的变量列表
const VarableVisualizableList = computed(() => {
  return dataRecordTabelTitle.value.filter((v) => {
    return v.ifVisualizable != 0;
  });
});
// 判断记录表格表头是否为空
const ifShowDataRecordList = computed(() => {
  return dataRecordTableLabelList.value.length == 0;
});
// 判断记录表格内容是否为空
const ifDataRecordEmpty = computed(() => {
  return dataRecordList.value.length == 0;
});
// 操作按钮列宽度自适应
const optionColumnWidth = computed(() => {
  return VarableVisualizableList.value.length == 0 ? null : 180;
});
const optionColumnAlgin = computed(() => {
  return VarableVisualizableList.value.length == 0 ? "center" : "left";
});
provide("ifDataRecordEmpty", ifDataRecordEmpty);
onMounted(() => {
  getDataRecordTabelLabelList();
  getDataRecordList();
});

defineExpose({ getDataRecordTabelLabelList });
</script>

<style lang="scss" scoped>
@import "@/assets/styles/components/list-table";
#data-record-table-wrapper {
  .search-bar {
    margin-bottom: 0px;
  }
  height: 100%;
  .option-btn-box {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 20px;
    .option-dropper-btn {
      // width: 120px;
      margin-right: 12px;
    }
  }
  .pagination-box {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
  }
  .table-container {
    .non-content-container {
      display: flex;
      flex-direction: column;
      height: calc($main-height - $main-footer-height - 140px);
    }
  }
}
</style>
