<template>
  <div id="data-record-wrapper">
    <PageTitle :title="title" />
    <div class="list-table">
      <DataRecordTable :info="folderLevelList.at(-1)" />
    </div>
  </div>
</template>

<script setup>
import { ref, defineEmits, onMounted } from "vue";
import { useRoute } from "vue-router";
import DataRecordTable from "./components/DataRecordTable.vue";
import PageTitle from "@/components/base/PageTitle.vue";
import {
  getFolderLevelListByTextLibraryId,
  getFolderBreadcrumbList
} from "./api/getFolderLevelList";
const title = "数据记录";
const route = useRoute();
const emits = defineEmits(["setBreadcrumbList"]);
const folderLevelList = ref([]);
const textLibraryId = route.params.textLibraryId;
const getFolderLevelList = async () => {
  folderLevelList.value = await getFolderLevelListByTextLibraryId(
    textLibraryId
  );
};
onMounted(async () => {
  await getFolderLevelList();
  let breadcrumblist = getFolderBreadcrumbList(folderLevelList.value);
  breadcrumblist.push({ title: title });
  emits("setBreadcrumbList", breadcrumblist);
});
</script>

<style lang="scss" scoped></style>
